import * as dateFns from "date-fns";
import settings from "../../../settings";

const Saltholdighet = (): JSX.Element => {
	const publicationDate = new Date("2021-07-01T10:00:00+0200");

	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/saltholdighet.jpg"} alt="" />
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Hvor salt er havet?</h1>
						<h5>Salt i havet gjør at du flyter bedre enn i ferskvann, men bare litt.</h5>
						<div className="text-muted">
							Publisert{" "}
							<time dateTime={publicationDate.toISOString()}>
								{dateFns.format(publicationDate, "dd.MM.yyyy")}
							</time>
							<div>Tekst: J. Even Ø. Nilsen</div>
						</div>
						<hr />
						<p>
							Havene har gjennom millioner av år samlet opp salt fra elvene som igjen har fått det fra
							berggrunnen. Vi måler saltholdighet i gram salt per kilogram havvann. Du kan, siden det bare
							er et forholdstall basert på vekt, bruke promille (‰). Vi havforskere bruker ikke enhet i
							det hele tatt, så ett kilogram havvann med 35 gram salt i seg, har saltholdighet 35.
						</p>
						<p>
							En vanlig konsentrasjon i verdenshavene er 35 ‰. Kyststrømmen vår har noe lavere
							saltholdighet (i gjennomsnitt 34 ‰). Om våren kan badevannet langs sørlandskysten nå ned i
							25 ‰. Lenger nord vil det være saltere fordi kyststrømmen blander seg med den salte
							Atlanterhavsstrømmen – også kjent som Golfstrømmen – på sin vei nordover. Kystvannet er
							ferskere enn verdenshavene fordi kyststrømmen består av elvevann som renner ut i Nordsjøen,
							Østersjøen og fra våre egne elver.
						</p>
						<p>
							Sammen med sjøtemperaturen påvirker saltholdigheten vannets egenvekt (tetthet) og dermed
							hvor godt vi flyter i det. I Dødehavet har saltholdigheten økt til det meste vann kan holde
							på (cirka 300 ‰), og der flyter du godt oppå overflaten. Det er langt fra slik rundt 35 ‰,
							og du vil nesten ikke merke forskjellene i saltholdighet på hvor godt du flyter ved ulike
							badeplasser langs kysten.
						</p>
						<p>
							Kilde: Sælen, Odd Henrik; Barthel, Knut: hav i Store norske leksikon på snl.no. Hentet 18.
							juni 2021 fra{" "}
							<a href="https://snl.no/hav" className="white-color url-underline">
								https://snl.no/hav
							</a>
							.
						</p>
					</div>
				</div>
			</article>
		</div>
	);
};

export default Saltholdighet;
export { Saltholdighet };
