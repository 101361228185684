import * as yup from "yup";

export const wmsListSchema = yup.object({
	/** Names of available layers */
	layerNames: yup.array(yup.string().required()).min(1).required(),
	/** URL to the WMS service the layers can be found at */
	layerUrl: yup.string().url().required()
});

export type WmsList = yup.Asserts<typeof wmsListSchema>;
