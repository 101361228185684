import { NorwegianVariableNames, VariableNames } from "../../types/VariableNames";
import settings from "../../settings";
import { BackendVariableNames } from "./types/BackendVariableNames";

/** Map between layer names and variables on frontend */
export const layerNameToFrontendVarName = new Map<string, VariableNames>(settings.LAYER_TO_VARIABLE_MAP);

/** Map between frontend variable names and layer names */
export const frontendVarNameToLayerNames = [...layerNameToFrontendVarName.entries()].reduce(
	(m, [layerName, varName]) => {
		// Get the existing set from the mapping
		const existing = m.get(varName) ?? new Set();

		// Add this layer name to the set
		const s = new Set([...existing, layerName]);

		// Put the new set in the map
		m.set(varName, s);

		return m;
	},
	new Map<VariableNames, Set<string>>()
);

/** Gets the first available layer corresponding to a given variable name */
export const getLayerForVariable = (varName: VariableNames, availableLayers: string[]): string | null => {
	// Get the list of potential layers for this variable
	const potentialLayers = frontendVarNameToLayerNames.get(varName) ?? new Set();

	return (
		[...potentialLayers]
			// Filter away the layers which do not exist
			.filter(layerName => availableLayers.includes(layerName))[0] ?? null
	);
};

/** Set of layers which have a mapping to a variable */
export const mappedLayerNames = new Set<string>(layerNameToFrontendVarName.keys());

/** Map between backend variable names and frontend variable names */
export const backendToFrontend = new Map<BackendVariableNames, VariableNames>([
	["temperature", "temperature"],
	["salinity", "salinity"],
	["wind", "wind"],
	["current", "current"]
]);

/** Map between frontend variable names and backend variable names */
export const frontendToBackend = new Map<VariableNames, BackendVariableNames>([
	["temperature", "temperature"],
	["salinity", "salinity"]
]);

export const translateVariableNames = new Map<VariableNames, NorwegianVariableNames>([
	["temperature", "temperatur"],
	["salinity", "saltholdighet"],
	["wind", "vind"],
	["current", "strøm"]
]);
