import * as yup from "yup";
import { representasjonspunktSchema } from "./Representasjonspunkt";
import { skrivemateJsonSchema } from "./SkrivemateJson";

export const punktSchema = yup.object({
	meterFraPunkt: yup.number().integer().required(),
	navneobjekttype: yup.string().required(),
	navneobjekttypekode: yup.string(), // XXX This is not actually in the data
	representasjonspunkt: representasjonspunktSchema.required(),
	stedsnavn: yup.array(skrivemateJsonSchema.required()).required(),
	stedsnummer: yup.number().integer().required()
});

export type Punkt = yup.Asserts<typeof punktSchema>;
