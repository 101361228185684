// import { VariableNames } from "../../types/VariableNames";
// import styles from "./ColorBar.module.scss";

const FunFacts = (): JSX.Element => {
	const PHRASES = [
		{ text: "Navnet «aure» har norrønt opphav og betyr «den som rir eller farer frem over grusbunnen»." },
		{ text: "Vi vet mer om verdensrommet enn om verdenshavene. " },
		{ text: "Det finnes sverdfisk i Norge. " },
		{ text: "Spermhvalen er den største av tannhvalene og er totalfredet i Norge. " },
		{ text: "Det finnes tre steinbitarter i Norge: blåsteinbit, flekksteinbit og gråsteinbit. " },
		{ text: "En voksen spekkhogger kan bli opptil 10 meter lang og veie 8 tonn. " },
		{ text: "Blekksprut som art har eksistert på jorden i over 500 millioner år. " },
		{
			text: "Blåhvalen er verdens største dyr, selv om vi regner med de største dinosaurene som døde ut for over 60 millioner år siden."
		},
		{ text: "Tungen til en blåhval kan veie like mye som en elefant. " },
		{
			text: "Du kan bidra med viktig folkeforskning ved å registrere merkelige fisker eller fisker du møter som er merket på Havforskningsinstituttet sine sider hi.no. "
		},
		{ text: "Barentshavet er nesten fire ganger så stort som Norge. " },
		{ text: "Den norske kystlinjen er verdens nest lengste, kun slått av Canada. " },
		{ text: "Saltvann fryser først ved nesten minus 2 grader. " },
		{ text: "Den norske kystlinjen er verdens nest lengste. Vi blir bare slått av Canada. " }
	];

	const phrase = PHRASES[Math.floor(Math.random() * PHRASES.length)];
	return (
		<div className="funfact text-center">
			<h2 className="white-color bold-text">{phrase.text}</h2>
		</div>
	);
};

export default FunFacts;
export { FunFacts };
