import { useNavigate } from "react-router-dom";

interface MapNavigationOptions {
	pathTemplate: string;
}

const useMapNavigation = ({ pathTemplate }: MapNavigationOptions) => {
	const navigate = useNavigate();
	const setPosZoom = (newPos: { lat: number; lng: number; zoom: number }) => {
		const { lat, lng, zoom } = newPos;
		const path = pathTemplate
			.replace(":lat", String(lat))
			.replace(":lng", String(lng))
			.replace(":zoom", String(zoom));
		navigate(path, { replace: true });
	};
	return { setPosZoom };
};

export default useMapNavigation;
