import settings from "../../../settings";

const KommerPaaHavvarsel = (): JSX.Element => {
	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img
						className="img-fluid col-12"
						src={settings.PUBLIC_URL + "/info/kommerpåhavvarsel.jpg"}
						alt=""
					/>
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Kommer på Havvarsel</h1>
						<h5>Vi vil utvide Havvarsel med flere moduler og funksjonalitet.</h5>
						<hr />
						<p>
							Badetemperatur og saltholdighet er bare første del av havvarsel. Prosjektet vil
							videreutvikle tjenesten havvarsel.no til å inneholde:
						</p>
						<ul>
							<li>Kartoppdatering hver time, og ikke bare en gang om dagen som nå</li>
							<li>
								Forbedre algoritmer for temperaturen for å forbedre avvik vi ser i dag (for eksempel på
								skjermet lokalitet)
							</li>
							<li>Hente inn ekstra informasjon fra yr.no, som lufttemperatur og sol/overskyet/nedbør</li>
							<li>Varsel for bølger, havnivå og havstrøm</li>
							<li>Vise flere dybdelag, og ikke bare overflaten</li>
						</ul>
						<p>På lengre sikt så tenker vi også på muligheter som:</p>
						<ul>
							<li>Få vist nærmeste observasjon (det vil si en måling fra et instrument i sjøen)</li>
							<li>
								Kunne gå tilbake i tid (“Hva var egentlig sjøtemperaturen 9 måneder før jeg ble født?”),
								og ikke bare fremover som i dag
							</li>
							<li>
								Kunne velge ett punkt og så se hvordan noe (for eksempel en gjenstand eller et utslipp)
								spres i vannmassene de neste par dagene, fra nåtid eller fra en historisk valgt dato
							</li>
						</ul>
						<p>
							Videre jobber vi allerede i prosjektet med spesialmoduler for søk- og redningstjenesten og
							mot oppdrettsnæringen. Disse vil nok ikke komme på havvarsel.no, men vil være støtteverktøy
							mot disse to bransjene.
						</p>
						<p>
							Vi tenker også på andre funksjonaliteter, som å kunne får SMS- eller epostvarsling når
							temperaturen er over en selvvalgt verdi (for eksempel “nå er badetemperaturen over 20°C”) og
							andre kjekke ting.
						</p>
						<p>
							Har du noen ideer eller ønsker så send oss gjerne en tilbakemelding på{" "}
							<a href="mailto:havvarsel@hi.no?subject=Havvarsel%20kontakt">
								<span className="white-color url-underline">havvarsel@hi.no</span>
							</a>
						</p>
					</div>
				</div>
			</article>
		</div>
	);
};

export default KommerPaaHavvarsel;
export { KommerPaaHavvarsel };
