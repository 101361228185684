import * as yup from "yup";

const options = {
	headers: new Headers([
		["content-type", "text/plain;charset=UTF-8"],
		["accept", "text/plain"]
	])
};

const textFetcher =
	<Data>(schema: yup.Schema<Data>) =>
	(url: string): Promise<Data> =>
		fetch(url, options)
			.then(res => res.text())
			.then(data => schema.validate(data));

export default textFetcher;
export { textFetcher };
