import { LatLng } from "../../types/LatLng";

/** Coordinates for center of norway, with a decent zoom level to view it all */
export const CENTER_OF_NORWAY = {
	position: {
		lat: 67.557,
		lng: 16.392
	} as LatLng,
	zoom: 4
};
