import * as dateFns from "date-fns";
import styles from "./TableData.module.scss";
import { AvailableDays } from "../../types/AvailableDays";

interface SelectDayProps {
	selectedDay: string;
	setSelectedDay: (s: AvailableDays) => void;
}

const days = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"];
const inactiveTabClass = styles["select-day-inactive-tab"];
const activeTabClass = styles["select-day-active-tab"];

const SelectDay = ({ selectedDay, setSelectedDay }: SelectDayProps): JSX.Element => {
	const overmorrow = dateFns.add(new Date(), { days: 2 });
	const overmorrowDayName = days[overmorrow.getDay()];

	return (
		<div className="background-primary-one">
			<div className="row justify-content-center g-0">
				<div className="col-4 col-md-2">
					<div
						className={`p-3 border no-wrap text-align-center ${
							selectedDay === "today" ? activeTabClass : inactiveTabClass
						}`}
						onClick={() => setSelectedDay("today")}
					>
						I dag
					</div>
				</div>
				<div className="col-4 col-md-2">
					<div
						className={`p-3 border no-wrap text-align-center ${
							selectedDay === "tomorrow" ? activeTabClass : inactiveTabClass
						}`}
						onClick={() => setSelectedDay("tomorrow")}
					>
						I morgen
					</div>
				</div>
				<div className="col-4 col-md-2">
					<div
						className={`p-3 border no-wrap text-align-center ${
							selectedDay === "overmorrow" ? activeTabClass : inactiveTabClass
						}`}
						onClick={() => setSelectedDay("overmorrow")}
					>
						{overmorrowDayName}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectDay;
export { SelectDay };
