import settings from "../../../settings";

const Havvarsel = (): JSX.Element => {
	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/havvarsel.jpg"} alt="" />
				</div>
				<div className="row mt-3">
					<div>Om oss</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Om Havvarsel</h1>
						<h5>
							Havvarsel sikter på å levere varsel om havets tilstand til deg som jobber tett på havet og
							deg som ferdes eller har interesser på, i og nær havet.
						</h5>
						<hr />
						<p>
							I likhet med værvarsler blir havvarsler beregnet ved hjelp av modeller kjørt på
							datamaskiner. Modellene bruker målinger til å starte og beregne frem i tid, men selve
							målingene er ikke presentert her. Du kan si at det du ser på tjenesten yr.no er havvarsel.no
							sin slektning for atmosfæren, og som gode slektninger så henger de sammen og utfyller
							hverandre.
						</p>
						<p>
							Havvarsel skal gjøre flere ulike typer av varsler tilgjengelige på en enkel måte. Akkurat nå
							er det sjøtemperatur i overflaten som er tilgjengelig, sammen med saltholdighet. Dette er
							nyttig for deg som vil ut og bade langs kysten vår. I fremtiden vil vi utvikle andre
							tjenester for aktiviteter og ferdsel på og i havet som bølger og havstrøm.
						</p>
						<h2>Hva kan Havvarsel brukes til?</h2>
						<h5>Havvarsel kan brukes til å planlegge aktiviteter på og i havet.</h5>
						<p>
							Værvarslet er et sentralt verktøy som vi bruker daglig for å ta både store og små
							avgjørelser. For deg som jobber tett på havet, så kan derimot et havvarsel være vel så
							viktig. For eksempel er redningstjenesten avhengig av gode prediksjoner av drivbane basert
							på havstrømmer, bølger og vind. Oppdrettsnæringen kan bruke prediksjoner av strømforhold for
							å planlegge hvor mye mat de skal gi og når. Og hvem vil ikke vite badetemperaturen for de
							neste dagene, når de er på ferie langs kysten?
						</p>
						<p>
							Husk at det alltid vil være usikkerheter knyttet til et varsel, det gjelder både værvarsel
							og havvarsel. Ta alltid hensyn til dette i planlegging av aktiviteter på havet.
						</p>
						<p>Vi tar ikke ansvar for eventuelle tap som følger av bruk av varselet.</p>
						<h2>Slik kan vi varsle i havet</h2>
						<h5>En havmodell bruker fysiske lover til å beregne havets tilstand frem i tid.</h5>
						<p>
							Havvarsel viser resultater fra en havmodell som heter NorKyst800. Havmodeller brukes til å
							beskrive sjøtemperatur, saltholdighet, strøm og vannstand i rom og tid. Modellene bruker
							målinger til å starte beregningene og regner så frem i tid basert på grunnleggende
							naturlovene som Newtons lover om bevaring av masse, bevegelse og energi.
						</p>
						<p>
							Dette gjør den for 800 x 800 meter celler i et horisontalt rutenett, og i 35 dybdenivåer.
							Modellen oppdateres to ganger daglig (ca. kl. 6 og kl. 18) og gir beregninger av havets
							tilstand 66 timer frem i tid.
						</p>
						<p>
							NorKyst800 vedlikeholdes og utvikles av Havforskningsinstituttet og Meteorologisk institutt.
						</p>
						<h2>Her er noen artikler om havmodeller: </h2>
						<a href="https://www.hi.no/hi/nyheter/2020/august/gode-skussmal-for-viktig-arbeidshest-i-havforskninga">
							<p className="bold-text white-color url-underline">
								Dokumenterte resultater fra HIs «havkalkulator»
							</p>
						</a>
						<p>
							Strømmodellen NorKyst800 beregner blant annet hvordan lakselus spres i fjordene. Forskerne
							har dokumentert at den gir resultater med høy presisjon.
						</p>
						<a href="https://www.hi.no/hi/nyheter/2020/november/slik-bruker-jon-strom-for-a-hjelpe-politiet-a-finne-savnede-mennesker">
							<p className="bold-text white-color url-underline">
								Slik bruker Jon havstrømmene for å hjelpe politiet å finne savnede mennesker
							</p>
						</a>
						<p>
							Når noen blir savnet på sjøen, ringer politiet HI-oseanograf Jon Albretsen. Han kan peke ut
							hvor politiet bør lete.
						</p>
						<a href="https://www.hi.no/hi/forskning/marine-data-forskningsdata/modeller-og-modellering/sirkulasjonsmodeller">
							<p className="bold-text white-color url-underline">Om strømmodeller</p>
						</a>
						<p>
							En modellkjøring trenger en startbeskrivelse av de fysiske variablene. Med dette som
							utgangspunkt regner modellen seg fram i tid.
						</p>
					</div>
				</div>
			</article>
		</div>
	);
};

export default Havvarsel;
export { Havvarsel };
