import queryString from "query-string";
import settings from "../../settings";
import jsonFetcher from "../../fetchers/jsonFetcher";
import textFetcher from "../../fetchers/textFetcher";
import { PointData } from "../../types/PointData";
import projectionToPointData from "./projectionToPointData";
import { FetchPointDataParams } from "./types/FetchPointDataParams";
import { GetProjection, getProjectionSchema } from "./types/GetProjection";
import { GetProjectionParams } from "./types/GetProjectionParams";
import { GetWindAndCurrentParams } from "./types/GetWindAndCurrentParams";
import { WindAndCurrentSchema, WindAndCurrent } from "./types/GetWindAndCurrent";
import { PolygonSchema } from "./types/Polygon";
import { WmsList, wmsListSchema } from "./types/WmsList";
import { frontendToBackend, mappedLayerNames } from "./variableMaps";
import { projectionParamsAtLayer } from "./projectionParamsAtLayer";

const BASE_URL = settings.BACKEND_BASE_PATH;

/** Calls the get_projection method on the backend */
export const getProjection = (params: GetProjectionParams): Promise<GetProjection> => {
	// Prepare the params for stringification
	const preparedParams = {
		after: params.after.toISOString(),
		before: params.before.toISOString(),
		depth: params.depth
	};
	const projectionvariables = Array.isArray(params.variable) ? params.variable.join(",") : params.variable;
	const pathVariables = `${projectionvariables}/${String(params.lon)}/${String(params.lat)}`;
	return jsonFetcher<GetProjection>(getProjectionSchema)(
		`${BASE_URL}/projection/${pathVariables}?${queryString.stringify(preparedParams)}`
	);
};

/** Calls the wmsList method on the backend */
export const wmsList = async (): Promise<WmsList> => {
	const { layerNames, layerUrl } = await jsonFetcher<WmsList>(wmsListSchema)(`${BASE_URL}/wmslist`);

	return {
		// Return the URL as it is
		layerUrl,
		// Remove layers which do not have a mapping to a variable
		layerNames: layerNames.filter(layerName => mappedLayerNames.has(layerName))
	};
};

/** Gets point data objects based on get_projection */
export const fetchPointData = async (
	params: FetchPointDataParams,
	setClosestGridPoint?: (c: { lat: number; lon: number }) => void
): Promise<PointData[]> => {
	// Figure out which backend variables the given vars correspond to
	const variable = (Array.isArray(params.variable) ? params.variable : [params.variable])
		.map(varName => frontendToBackend.get(varName))
		.filter(varName => varName !== undefined);

	// Modify the param object for the getProjection method
	const projectionParams = {
		...params,
		variable
	} as GetProjectionParams;

	// Fetch the data
	const projectionData = await getProjection(projectionParams);

	const { lat, lon } = projectionData.closestGridPointWithData;

	if (setClosestGridPoint && projectionData) {
		setClosestGridPoint({ lat, lon });
	}

	// Convert it to point data
	return projectionToPointData(projectionData);
};

export const fetchWindAndCurrentData = async (params: GetWindAndCurrentParams): Promise<WindAndCurrent> => {
	const queryString = Object.values(params).join("/");
	const url = `${BASE_URL}/windandcurrentprojection/${queryString}`;
	const fetchingData = await jsonFetcher<WindAndCurrent>(WindAndCurrentSchema)(url);
	return fetchingData;
};

export const fetchWindAndCurrentPolygon = async (): Promise<string> => {
	return await textFetcher<string>(PolygonSchema)(`${BASE_URL}/gis/polygon`);
};

export const fetchLayeredProjections = async (pointData: PointData[]): Promise<GetProjection[]> => {
	// List of projections to be returned for display
	const projections: GetProjection[] = [];
	let exit: boolean = false;
	for (let i = 0; i <= 15 && !exit; i++) {
		// Loop through layerIndex 0 to 15
		// Params for current projection layer in the loop
		const params = projectionParamsAtLayer(pointData, i);
		// get projection at depthIndex = i
		await getProjection(params)
			.then(response => {
				projections.push(response);
			})
			.catch(() => {
				exit = true;
			});
	}
	return projections;
};
