import styles from "./Watermark.module.scss";

interface DisplayTextProps {
	displaytext: string;
	styleClass?: string;
	capitalLetter: boolean;
}

const Watermark = ({ displaytext, styleClass: classname, capitalLetter }: DisplayTextProps): JSX.Element | null => {
	const className = classname ? styles[classname] : "";
	const textToDisplay =
		capitalLetter && displaytext !== "" ? displaytext.charAt(0).toUpperCase() + displaytext.slice(1) : displaytext;

	return (
		<span>
			<div className={className}>{textToDisplay}</div>
		</span>
	);
};

export default Watermark;
export { Watermark as Watermark };
