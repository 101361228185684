import settings from "../../../settings";

const SlikBrukesHavvarsel = (): JSX.Element => {
	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img
						className="img-fluid col-12"
						src={settings.PUBLIC_URL + "/info/slikbrukeshavvarsel.jpg"}
						alt=""
					/>
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Slik bruker du Havvarsel</h1>
						<h5>
							Varslene kan ses på flere måter, i kartløsning eller i tabeller og grafer som viser
							tidsutviklingen fremover.
						</h5>
						<hr />
						<p>
							Første siden du får opp, er hovedsiden for Havvarsel. Dette er inngangsporten til alle
							modulene. Foreløpig er alle verdier i Havvarsel fra havoverflaten.
						</p>
						<p>
							Når du velger sted får du opp tre faner; for i dag og de to neste dagene. Her kan du se
							dagens sjøtemperatur sammen med en tabell med sjøtemperaturer for hver sjette time fremover.
							Du kan utvide tabellen til å vise time for time i det døgnet du har valgt. Saltholdighet
							vises også.
						</p>
						<p>
							Under tabellen ser du bilde av et kart med farger for temperatur i havet i dag. Velger du
							«utforsk kartet», kommer du til kartløsningen for badetemperaturer. Her kan du utforske
							badetemperaturer andre steder i landet ved å klikke på sted eller søke på navn. Du kan også
							få opp et detaljert varsel med graf og tabeller for stedet du velger. Du kan også velge å
							utforske saltholdighet.
						</p>
						<p>
							Strøm og vind vises med piler. Her må du zoome inn for å se pilene på kartet. Vi har gjort
							det slik for at pilene ikke skal gå inn i hverandre og for at det ikke skal gå for tregt å
							flytte seg rundt. Pilene er alltid like lange, mens fargen på pilene indikerer styrken. Også
							når pilene vises kan du trykke på et sted og få opp tall, men ikke detaljert varsel.
						</p>
						<p>
							Dataene er modellresultater for et 800 x 800 meter rutenett og hver verdi er derfor
							representativ for hele ruten. Du får derfor samme verdi uansett hvor i hver enkelt rute du
							trykker. Det som kan virke som grove «pixler» på kartet er altså modellens rutenett ærlig
							presentert. «What you see is what you get.» Piler starter midt i rutene.
						</p>
						<p>
							For at du lett skal finne fram har vi valgt landkart fra{" "}
							<a href="https://www.openstreetmap.org/copyright" className="white-color url-underline">
								“OpenStreeMap”
							</a>
							. Det gjør at det forekommer diverse markeringer av fjelltopper, ferjeruter og liknende.
							Disse er ikke en del av tjenesten og har ingenting med Havvarsel å gjøre.
						</p>
					</div>
				</div>
			</article>
		</div>
	);
};

export default SlikBrukesHavvarsel;
export { SlikBrukesHavvarsel };
