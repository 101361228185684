import { useContext, useEffect } from "react";
import MapContext from "./MapContext";
import PinchRotate from "ol/interaction/PinchRotate";
import Interaction from "ol/interaction/Interaction";

const NoRotationHack = (): null => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		// Need the map to be able to do anything
		if (map === null) {
			return;
		}

		const interactions = [] as Interaction[];
		map.getInteractions().forEach(interaction => interactions.push(interaction));

		const pinchRotateInteraction = interactions.find(interaction => interaction instanceof PinchRotate);

		if (pinchRotateInteraction !== undefined) {
			map.removeInteraction(pinchRotateInteraction);
		}
	}, [map]);

	return null;
};

export default NoRotationHack;
export { NoRotationHack };
