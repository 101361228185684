import { PointData } from "../../types/PointData";
import { GetProjectionParams } from "./types/GetProjectionParams";

export const projectionParamsAtLayer = (pointData: PointData[], depthIndex: number): GetProjectionParams => {
	// Create params at given layer
	const layerParams: GetProjectionParams = {
		after: pointData[0].timestamp,
		before: pointData[pointData.length - 1].timestamp,
		lat: pointData[0].position.lat,
		lon: pointData[0].position.lng,
		depth: depthIndex,
		variable: ["temperature", "salinity"] // Vertical profiles only for temp and sal as for now
	};
	return layerParams;
};
