import * as yup from "yup";
import { VariableNames, variableNames } from "./VariableNames";

export const settingsSchema = yup.object({
	NODE_ENV: yup.string().oneOf(["development", "test", "production"]).defined(),
	PUBLIC_URL: yup.string().default(""),
	BACKEND_BASE_PATH: yup.string().required(),
	GA_TOKEN: yup.string().required(),
	LAYER_TO_VARIABLE_MAP: yup
		// Need tuples of [string, string]
		.array(yup.array(yup.string().required()).length(2).required())
		// Must have a value
		.required()
		// Need the second values of the tuples to be a valid variable name
		.test({
			name: "only-valid-variables",
			test: value =>
				value?.every(
					tuple => tuple?.[1] !== undefined && variableNames.includes(tuple?.[1] as VariableNames) // XXX The `as` is a hack, as .include for some reason limits its arguments to the types in the array, not `string`
				) ?? false,
			message: `Environment variable REACT_APP_LAYER_TO_VARIABLE_MAP must be an array with tuples ${variableNames
				.map(varName => `[string, "${varName}"]`)
				.join(", ")}`
		})
});

// Narrow the type a bit, as the structure of REACT_APP_LAYER_TO_VARIABLE_MAP has been verified by the schema
export type Settings = yup.Asserts<typeof settingsSchema> & { LAYER_TO_VARIABLE_MAP: [string, VariableNames][] };
