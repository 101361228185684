import { useParams } from "react-router-dom";
import useMapNavigation from "./useMapNavigation";
import { posParser } from "./utils";
import ErrorBoundary from "../ErrorBoundary";
import MapPage from "../pages/MapPage";

const MapRoute = () => {
	const { lat, lng, zoom } = useParams<{ lat?: string; lng?: string; zoom?: string }>();
	const { setPosZoom } = useMapNavigation({
		pathTemplate: "/bade/:lat/:lng/:zoom"
	});

	if (lat === undefined || lng === undefined) {
		// Handle the case where lat or lng is undefined, e.g., redirect or show an error message
		return <p>Error: Latitude or Longitude is missing.</p>;
	}

	const position = posParser({ lat, lng, zoom });
	return (
		<>
			<ErrorBoundary fallback={<p>Error</p>} />
			<MapPage position={position} zoom={position.zoom} setPosZoom={() => setPosZoom(position)} />;
		</>
	);
};
export default MapRoute;
