import * as yup from "yup";
import { representasjonspunktSchema } from "./Representasjonspunkt";
import { kommunerSchema } from "./Kommuner";

export const skrivemateSchema = yup.object({
	kommuner: yup.array(kommunerSchema.required()).nullable().defined(), // XXX This is null for example on oil installations
	navneobjekttype: yup.string().required(),
	navneobjekttypekode: yup.string(), // XXX This is not actually in the data
	navnestatus: yup.string().required(),
	representasjonspunkt: representasjonspunktSchema.required(),
	skrivemåte: yup.string().required(),
	skrivemåtestatus: yup.string().required(),
	språk: yup.string().required(),
	språkkode: yup.string(), // XXX This is not actually in the data
	stedsnummer: yup.number().integer().required()
});

export type Skrivemate = yup.Asserts<typeof skrivemateSchema>;
