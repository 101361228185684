import * as dateFns from "date-fns";
import settings from "../../../settings";

const SoettOgSalt = (): JSX.Element => {
	const publicationDate = new Date("2021-07-01T10:00:00+0200");

	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/sottogsalt.jpg"} alt="" />
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Søtt og salt</h1>
						<h5>
							På engelsk kan ferskvann kalles sweetwater og på svensk sötvatten. Sjøvann kan være både
							ferskt og salt fra sted til sted. Men hva kan du bruke saltholdigheten i Havvarsel til? Har
							saltholdighet noe å si for badetemperaturen, for eksempel?
						</h5>
						<div className="text-muted">
							Publisert{" "}
							<time dateTime={publicationDate.toISOString()}>
								{dateFns.format(publicationDate, "dd.MM.yyyy")}
							</time>
							<div>Tekst: J. Even Ø. Nilsen</div>
						</div>
						<hr />
						<p>
							Se på bildet til venstre. Det er fra slutten av juni. Hvorfor er lille, lune Drammensfjorden
							så mye kaldere enn Oslofjorden? Kanskje gjetter du at det har noe med elver å gjøre. Da er
							det bare å trykke på fanen for saltholdighet, så ser du hvorfor: På bildet til høyre er det
							tydelig at sjøen er mindre salt i Drammensfjorden. Drammenselva fører med seg ferskt
							smeltevann til Drammensfjorden, fra områder helt opp til Jotunheimen, Filefjell og
							Hardangervidda. Det er altså ikke så rart at vannet er kaldere der.
						</p>
						<p>
							På denne måten kan du utforske havet der du bor eller er på ferie. Finnes det elver som
							fører med seg kaldt smeltevann der du er?
						</p>
						<div className="row">
							<div className="col-6 soett-og-salt-smaller-image-container">
								<img
									src={settings.PUBLIC_URL + "/soettogsalt1.jpeg"}
									className="soett-og-salt-smaller-image"
								/>
							</div>
							<div className="col-6 soett-og-salt-smaller-image-container">
								<img
									src={settings.PUBLIC_URL + "/soettogsalt2.jpeg"}
									className="soett-og-salt-smaller-image"
								/>
							</div>
						</div>
						<p>
							Sjøtemperatur til venstre og saltholdighet til høyre. Grunnen til at Drammensfjorden er
							kaldere, er smeltevannet som Drammenselva fører med seg. Slik er det ved de fleste elveutløp
							i landet.
						</p>
					</div>
				</div>
			</article>
		</div>
	);
};

export default SoettOgSalt;
export { SoettOgSalt };
