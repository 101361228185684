import * as yup from "yup";
import { metadataSchema } from "./Metadata";
import { skrivemateSchema } from "./Skrivemate";

export const returSkrivemateSchema = yup.object({
	metadata: metadataSchema.required(),
	navn: yup.array(skrivemateSchema.required()).required()
});

export type ReturSkrivemate = yup.Asserts<typeof returSkrivemateSchema>;
