const MeasurementUnits = {
	temperature: {
		symbol: " °C"
	},
	salinity: {
		symbol: " ‰"
	},
	wind: {
		symbol: " m/s"
	},
	current: {
		symbol: " m/s"
	}
} as const;

export default MeasurementUnits;
export { MeasurementUnits };
