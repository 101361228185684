import { useState } from "react";
import * as dateFns from "date-fns";
import { PointData } from "../../types/PointData";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./TableData.module.scss";

interface HourlyTableDataProps {
	data: PointData[];
}

// Define all available hours
const hours = Array(24)
	.fill(1)
	.map((_x, i) => (i + 1) % 24);

// Define the hours at which the short table will display data
const shortHours = [6, 12, 18, 0];

const HourlyTableData = ({ data }: HourlyTableDataProps): JSX.Element => {
	const [showMoreData, setShowMoreData] = useState(false);

	// Choose the list to make the table from
	const hourList = showMoreData ? hours : shortHours;

	return (
		<div className={`${styles["background-secondary-two"]}`}>
			<table className="no-margin table table-hover border-bottom-0 border-error">
				<thead>
					<tr>
						<th>Tid</th>
						<th>Sjøtemp</th>
						<th>Salt</th>
					</tr>
				</thead>
				<tbody>
					{hourList.map(hour => {
						// Find the data for that hour
						const pointData =
							data.find(({ timestamp }) => dateFns.getHours(timestamp) === hour) ??
							({
								timestamp: dateFns.set(new Date(), { hours: hour, minutes: 0 }),
								position: { lat: 0, lng: 0 } // The position is irrelevant to this component
							} as PointData);

						// Figure out what to display
						const timestring = dateFns.format(pointData.timestamp, "HH:mm");
						const temperature = pointData.temperature ? pointData.temperature.toFixed(1) : null;
						const salinity = pointData.salinity?.toFixed(0) ?? null;

						const fallback = <td>Ingen data</td>;

						// Figure out if this data is for the current hour
						// XXX This could cause false positives if the table is showing data for another day, and there
						// is no data for this hour, due to the default fallback above. I'm not sure how to fix
						// that in a good way, as this component has no idea which day it is rendering, and it
						// also renders one hour into the next day
						const isThisHour = dateFns.isThisHour(pointData.timestamp);

						return (
							<tr key={hour} className={isThisHour ? styles["this-hour"] : ""}>
								<td>
									<span>{timestring}</span>
								</td>
								{temperature !== null ? <td>{temperature} °C</td> : fallback}
								{salinity !== null ? (
									<td>
										{salinity} {"‰"}
									</td>
								) : (
									fallback
								)}
							</tr>
						);
					})}
				</tbody>
			</table>
			<button className={`col-12 ${styles["show-more-data-button"]}`} onClick={() => setShowMoreData(s => !s)}>
				Time for time{" "}
				{showMoreData ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
			</button>
		</div>
	);
};

export default HourlyTableData;
export { HourlyTableData };
