import React, { useEffect, useMemo, useState } from "react";
// import { availableDays, AvailableDays } from "../../types/AvailableDays";
import { AvailableDays } from "../../types/AvailableDays";
import Chart from "../map/charts/Chart";
import HourlyTableData from "../tables/HourlyTableData";
import { PointData } from "../../types/PointData";
import { VariableNames } from "../../types/VariableNames";
import Details from "./Details";
import * as dateFns from "date-fns";
import styles from "./DisplayData.module.scss";
import MeasurementUnits from "../misc/MeasurementUnits";
import { fetchLayeredProjections } from "../../apis/backend";
import { GetProjection } from "../../apis/backend/types/GetProjection";
import HeatChart from "../map/charts/HeatMap";
import Loading from "../misc/LoadingComponent";

interface DisplayDataProps {
	variableName: VariableNames; // Might not need
	locationName: string | null;
	pointData: PointData[];
	setShowGraph: React.Dispatch<React.SetStateAction<boolean>>;
}

const DisplayData = ({ variableName, locationName, pointData, setShowGraph }: DisplayDataProps): JSX.Element => {
	// Set and update the layered projection data
	const [layeredData, setLayeredData] = useState<GetProjection[]>([]);
	// Display loading component while layerdata loads
	const [showHeatMap, setShowHeatMap] = useState(false);
	// Keep track of when layered data is loading
	const [isLoading, setisLoading] = useState(true);
	// Define the available days. For today, it's the current hour. For the others, it's 12:00
	const today = dateFns.startOfHour(new Date());
	const dayMap = new Map<AvailableDays, Date>([
		["today", today],
		["tomorrow", dateFns.set(dateFns.add(today, { days: 1 }), { hours: 12 })],
		["overmorrow", dateFns.set(dateFns.add(today, { days: 2 }), { hours: 12 })]
	]);

	// Find the timestamp to use for current data
	const dataHour = dayMap.get("today") ?? new Date(); // XXX The default is there just to get the type right. It will never happen

	// Find the data for that timestamp
	const currentPointData = pointData?.find(({ timestamp }) => dateFns.isSameHour(timestamp, dataHour));
	const temperature = currentPointData?.temperature;
	const salinity = currentPointData?.salinity;

	// Divide the data into days
	// const [todayData, tomorrowData, overmorrowData] = availableDays
	// 	.map(dayStr => dayMap.get(dayStr) ?? new Date())
	// 	.map(day => pointData.filter(({ timestamp }) => dateFns.isSameDay(timestamp, day)));

	// TODO: Make it work
	// XXX HACKY: The commented code above includes the same day (00:00 of the same day comes last), copy paste from overview instead for quickfix
	// XXX Really, change the code below soon

	// Filter out the data for the desired day
	// Define the timespan from which to extract data
	const day = dayMap.get("today") ?? new Date(); // XXX The default is there just to get the type right. It will never happen
	const tomorrow = dayMap.get("tomorrow") ?? new Date(); // XXX The default is there just to get the type right. It will never happen
	const overmorrow = dayMap.get("overmorrow") ?? new Date(); // XXX The default is there just to get the type right. It will never happen

	const makeFilterFn =
		(dayTs: Date) =>
		({ timestamp }: PointData) => {
			const after = dateFns.add(dateFns.startOfDay(dayTs), { hours: 1, seconds: -1 }); // `isAfter` is exclusive, so need a timesamp slightly earlier than what I actually want
			const before = dateFns.add(after, { days: 1 });

			return dateFns.isAfter(timestamp, after) && dateFns.isBefore(timestamp, before);
		};

	const todayData = pointData?.filter(makeFilterFn(day)) ?? [];
	const tomorrowData = pointData?.filter(makeFilterFn(tomorrow)) ?? [];
	const overmorrowData = pointData?.filter(makeFilterFn(overmorrow)) ?? [];

	// Used to prevent rerenders of Chart when data for heatmap is done fetching
	const memoizedGraph: JSX.Element = useMemo(() => {
		return <Chart variable={variableName} dataForGraph={pointData} />;
	}, [pointData, variableName]);

	useEffect(() => {
		const fetchData = async () => {
			setisLoading(true);
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			await fetchLayeredProjections(pointData)
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
				.then(data => setLayeredData(data))
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				.then(() => setisLoading(false));
		};
		fetchData().catch(() => {
			return;
		});
	}, [pointData]);

	return (
		<div className="container-fluid background-secondary-one p-2">
			<div className=" d-flex justify-content-between">
				<div className="col">
					<h2 className={`font-color-primary-one ${styles["location-name-size"]}`}>{locationName}</h2>
				</div>
				<div className="col-auto">
					<button
						className={`btn btn-unstyled b ${styles["exit-button-size"]}`}
						onClick={() => setShowGraph(false)}
					>
						X
					</button>
				</div>
			</div>
			{temperature && variableName === "temperature" ? (
				<div>
					<div className={`col-12 font-color-primary-one ${styles["temperature-font-size"]}`}>
						{Math.round(temperature)} {MeasurementUnits.temperature.symbol}
					</div>
				</div>
			) : null}
			{salinity && variableName === "salinity" ? (
				<div>
					<div className={`col-12 font-color-primary-one ${styles["temperature-font-size"]}`}>
						{Math.round(salinity)} {MeasurementUnits.salinity.symbol}
					</div>
				</div>
			) : null}
			<hr />
			<div>
				<section className="col-12">
					<div className={`${styles["display-data-title-toggle"]}`}>
						<h2 className={`font-color-primary-one ${styles["display-data-title-font"]}`}>Vis dybdelag</h2>
						<input
							type="checkbox"
							id="switch"
							onChange={() => {
								showHeatMap ? setShowHeatMap(false) : setShowHeatMap(true);
							}}
						/>
						<label htmlFor="switch">Toggle</label>
					</div>
					{!showHeatMap ? (
						memoizedGraph
					) : showHeatMap && isLoading ? (
						<Loading />
					) : showHeatMap && !isLoading ? (
						<HeatChart variable={variableName} dataForGraph={layeredData} />
					) : null}
				</section>
			</div>
			<hr />
			<div>
				<section className="col-12">
					<h2 className={`font-color-primary-one ${styles["display-data-title-font"]}`}>Tabell</h2>
					<section>
						<h3 className={styles["day-text"]}>I dag</h3>
						<HourlyTableData data={todayData} />
					</section>
					<section>
						<h3 className={styles["day-text"]}>I morgen</h3>
						<HourlyTableData data={tomorrowData} />
					</section>
					<section>
						<h3 className={styles["day-text"]}>I overmorgen</h3>
						<HourlyTableData data={overmorrowData} />
					</section>
				</section>
			</div>
			<hr />
			<div>
				<div className="col-12">
					{pointData.length > 0 ? <Details position={pointData[0].position} /> : null}
				</div>
			</div>
		</div>
	);
};

export default DisplayData;
export { DisplayData };
