import { useState, useEffect, useRef } from "react";
import MapContext from "./MapContext";
import OMap from "ol/Map";
import * as proj from "ol/proj";
import "ol/ol.css";
import { LatLng } from "../../types/LatLng";
import Zoom from "ol/control/Zoom";
import DoubleClickZoom from "ol/interaction/DoubleClickZoom";
import { Extent } from "ol/extent";
import ScaleLine from "ol/control/ScaleLine";
import useMapNavigation from "../router/useMapNavigation";

const scaleLineControl = new ScaleLine({
	minWidth: 120
});
interface MapComponentProps {
	/** The desired zoom level of the map */
	zoom: number;
	/** Desired center of the map */
	center: LatLng;
	/** Callback for when position and/or zoom level changes */
	onMoveEnd?: (newPosition: LatLng, newZoom: number) => unknown;
	/** Set the map rectangle function*/
	setLatLongRectangle?: (rectange: Extent | null) => void;
	children?: React.ReactNode;
}
const Map = ({ zoom, center, onMoveEnd, setLatLongRectangle, children }: MapComponentProps): JSX.Element => {
	const [newLat, setLat] = useState<number | undefined>(center.lat);
	const [newLng, setLng] = useState<number | undefined>(center.lng);
	const [newerZoom, setZoom] = useState<number | undefined>(zoom);
	const mapRef = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState<OMap | null>(null);
	const ZOOM_THRESHOLD = 10;
	const [hasBeenAccessed, setHasBeenAccessed] = useState(false);
	const { setPosZoom } = useMapNavigation({ pathTemplate: "/bade/:lat/:lng/:zoom" });

	map?.addControl(scaleLineControl);
	// Set up the map when the component mounts
	useEffect(() => {
		// This should never happen, but is there to stop TS from complaining about null
		if (mapRef.current === null) {
			return;
		}

		// Set up initial options for the map. Zoom and center are handled in other `useEffect`s further down
		const options = {};

		// Initialize the map
		const mapObject = new OMap(options);

		// Bind the map to the DOM
		mapObject.setTarget(mapRef.current);

		// Store the map on the state
		setMap(mapObject);

		// Clean up the map when the component is unmounted
		return () => {
			mapObject.setTarget(undefined);
			mapObject.dispose();
			setMap(null);
		};
	}, []);

	const moveEndHandler = (): void => {
		if (onMoveEnd === undefined || map === null) {
			return;
		}
		const newCenterProjected = map.getView().getCenter() ?? [0, 0];
		const newZoom = map.getView().getZoom() ?? 1;

		// Center is in the map's projection's format. Convert it to [lon, lat]
		const [lng, lat] = proj.toLonLat(newCenterProjected, map.getView().getProjection());

		// Give them to the callback
		onMoveEnd({ lat, lng }, newZoom);
		setLat(lat);
		setLng(lng);
		setZoom(newZoom);
	};
	useEffect(() => {
		if (hasBeenAccessed) {
			setPosZoom({ lat: newLat as number, lng: newLng as number, zoom: newerZoom as number });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newLat, newLng, newerZoom]);

	// Hook up the events to the handlers
	useEffect(() => {
		// Need the map and a callback to be able to do anything
		if (map === null || onMoveEnd === undefined) {
			return;
		}

		map.on("moveend", moveEndHandler);

		return () => {
			// Clean up when the map unmounts
			map.un("moveend", moveEndHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map, onMoveEnd]);

	// Handle changes to the zoom and center prop
	const { lat, lng } = center; // XXX useEffect's dependency array is shallowly compared, which causes it to rerun on every render if `center` is not literally the same array. Use its parts instead
	useEffect(() => {
		// Do not run without a map
		if (map === null) {
			return;
		}

		// Center is in [lon, lat] format. Convert it to the map's projection
		// See https://openlayers.org/en/latest/doc/faq.html#why-is-the-order-of-a-coordinate-lon-lat-and-not-lat-lon-
		const projCenter = proj.fromLonLat([lng, lat], map.getView().getProjection());

		// Animate the map to the new center/zoom
		map.getView().animate({ zoom, center: projCenter, duration: 500 });

		// Remove zoom buttons (+/-)
		map.getControls().forEach(x => {
			if (x instanceof Zoom) {
				map.removeControl(x);
			}
		});
		// Remove doubleclick. This prevents zoom in and out before popup shows.
		map.getInteractions().forEach(x => {
			if (x instanceof DoubleClickZoom) {
				map.removeInteraction(x);
			}
		});

		const mapExtent = map.getView().calculateExtent(map.getSize());
		const latLongRectangular = proj.transformExtent(mapExtent, "EPSG:3857", "EPSG:4326");

		// Change zoom level to variable
		if (setLatLongRectangle && zoom >= ZOOM_THRESHOLD) {
			setLatLongRectangle(latLongRectangular);
		} else if (setLatLongRectangle && zoom < ZOOM_THRESHOLD) {
			setLatLongRectangle(null);
		}
	}, [map, zoom, lat, lng, setLatLongRectangle, hasBeenAccessed]);

	useEffect(() => {
		setHasBeenAccessed(true);
	}, []);

	return (
		<MapContext.Provider value={{ map }}>
			<div ref={mapRef} style={{ width: "100%", height: "100%" }}>
				{children}
			</div>
		</MapContext.Provider>
	);
};

export default Map;
export { Map };
