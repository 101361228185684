// Loading Component
function Loading() {
	return (
		<div className="loader-container">
			<div className="loader">Laster inn...</div>
		</div>
	);
}

export default Loading;
