import * as yup from "yup";

export const metadataSchema = yup
	.object({
		side: yup.number().integer().required(),
		sokeStreng: yup.string().required(),
		totaltAntallTreff: yup.number().integer().required(),
		treffPerSide: yup.number().integer().required(),
		viserFra: yup.number().integer().required(),
		viserTil: yup.number().integer().required()
	})
	.required();

export type Metadata = yup.Asserts<typeof metadataSchema>;
