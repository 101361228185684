import * as dateFns from "date-fns";
import settings from "../../../settings";

const HavvarselForskjell = (): JSX.Element => {
	const publicationDate = new Date("2022-07-01T10:00:00+0200");

	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img
						className="img-fluid col-12"
						src={settings.PUBLIC_URL + "/info/havvarselforskjell.jpg"}
						alt=""
					/>
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Hvorfor kan man få forskjeller mellom Havvarsel og virkeligheten?</h1>
						<h5>Lune og grunne badebukter er mindre enn en rute i modellens rutenett.</h5>
						<div className="text-muted">
							Publisert{" "}
							<time dateTime={publicationDate.toISOString()}>
								{dateFns.format(publicationDate, "dd.MM.yyyy")}
							</time>
							<div>Tekst: Jon Albretsen</div>
						</div>
					</div>
					<hr />
					<p>
						Modelldataene som inngår i Havvarsel kommer fra en havmodell som dekker hele norskekysten, men
						har en fast oppløsning med ruter på 800 x 800 meter. Hvert beregningspunkt representerer et
						område som er over en halv kvadratkilometer. I kartløsningen kan du tydelig se rutenettet.
					</p>
					<p>
						De populære badeplassene ligger gjerne nært land og har sin spesielle kystlinje og vanndyp som
						modellen ikke kan gjenskape med dette rutenettet. Havvarsel vil derfor fungere best for de litt
						mer åpne sjøområdene. Spesielt sommerstid vil vannet kunne være varmere i lune viker og
						strender. Du vil sikkert også oppleve noe avvik mellom modell og virkelighet i åpne områder
						også, men slike feil og usikkerheter arbeider modellører på Havforskningsinstituttet og
						Meteorologisk institutt utrettelig med å redusere.
					</p>
					<p>
						Strøm kan også være forskjellig fra det som er observert eller opplevd. Modellens oppløsning vil
						ha stor betydning for å gjengi strømforhold i trange farvann. Øyer, holmer, sund og variert
						skjærgård vil ikke være representert realistisk.
					</p>
					<p>Sjøtemperaturen i din rute vil likevel gi en god pekepinn om det er verdt å dra ut og bade.</p>
				</div>
			</article>
		</div>
	);
};

export default HavvarselForskjell;
export { HavvarselForskjell };
