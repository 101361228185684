import * as yup from "yup";
import { latLngSchema } from "./LatLng";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";

export const savedPlaceSchema = yup.object({
	name: yup.string().required(),
	placeType: yup.string().required(),
	kommune: yup.string().nullable().defined(),
	position: latLngSchema.required()
});

export type Place = yup.Asserts<typeof savedPlaceSchema>;

const LOCALSTORAGE_KEY = "savedPlaces";

/** Retrieves all saved places from localStorage */
export const getPlaces = (): Place[] => {
	// Get the raw data from localStorage
	const rawData = localStorage.getItem(LOCALSTORAGE_KEY);

	try {
		// Try to parse it
		const unvalidatedData = JSON.parse(rawData ?? JSON.stringify([])) as unknown;

		// The result should be an array
		if (!Array.isArray(unvalidatedData)) {
			throw new Error("Place data from localStorage is not an array");
		}

		// Validate them all
		// XXX Normally I would map invalid entries to null, then filter them, but TS didn't understand there were
		// no nulls after the filter. Went for flatMap instead
		return unvalidatedData.flatMap(data => {
			try {
				return [savedPlaceSchema.validateSync(data)];
			} catch (err) {
				// Invalid entry... Filter it out
				return [];
			}
		});
	} catch (err) {
		// Default to an empty list
		return [];
	}
};

/** Saves a place to localStorage */
export const savePlace = (place: Place): void => {
	// Get the already saved places
	const existingPlaces = getPlaces();

	// Add this one and remove duplicates
	const newPlaceArray = uniqWith([place, ...existingPlaces], isEqual);

	// Write it back to localStorage
	localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newPlaceArray));
};
