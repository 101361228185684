import { Extent } from "ol/extent";

// Function for increasing an extent (two coordinates representing a rectangle)
/**
 *
 * @param extent coordinates in array, representing a rectangle [x1, y1, x2, y2]
 * @param percentageIncrease percentage the extent will be increased by - in decimal number.
 * @returns new larger extent.
 */
const increaseExtent = (extent: Extent, percentageIncrease: number): Extent => {
	const [x1, y1, x2, y2] = extent;

	const xSize = x2 - x1;
	const ySize = y2 - y1;

	const increastedExtent = {
		lon1: x1 - xSize * percentageIncrease,
		lat1: y1 - ySize * percentageIncrease,
		lon2: x2 + xSize * percentageIncrease,
		lat2: y2 + ySize * percentageIncrease
	};

	return [...Object.values(increastedExtent)] as Extent;
};

export default increaseExtent;
export { increaseExtent };
