import { Settings, settingsSchema } from "./types/Settings";

// Validate the environment variables
// XXX Values from the environment are always strings. Need to convert them to proper types before giving them
// to the schema
// XXX Apps created with create-react-app ignore (almost)
// all environment variables not starting with "REACT_APP_". See
// https://create-react-app.dev/docs/adding-custom-environment-variables/

const rawEnv = process.env;

const settings = settingsSchema.validateSync({
	NODE_ENV: rawEnv.NODE_ENV, // Set automatically by CRA depending on build environment
	PUBLIC_URL: rawEnv.PUBLIC_URL, // Set automatically by CRA during build
	BACKEND_BASE_PATH: rawEnv.REACT_APP_BACKEND_BASE_PATH,
	GA_TOKEN: rawEnv.REACT_APP_GA_TOKEN,
	LAYER_TO_VARIABLE_MAP: JSON.parse(rawEnv.REACT_APP_LAYER_TO_VARIABLE_MAP ?? "invalid") as unknown
}) as Settings;

export default settings;
export { settings };
