import React from "react";
import { Overlay } from "ol";
import { useState, useRef, useEffect, useContext } from "react";
import MapContext from "../MapContext";
import * as proj from "ol/proj";
import { VariableNames } from "../../../types/VariableNames";
import { PointData } from "../../../types/PointData";
import { LatLng } from "../../../types/LatLng";
import styles from "./Popup.module.scss";
import { Current, Wind } from "../../../apis/backend/types/Wind";
import MeasurementUnits from "../../misc/MeasurementUnits";

interface PopupProps {
	pointData: PointData | null;
	locationName: string | null;
	variableName: VariableNames;
	setShowGraph: React.Dispatch<React.SetStateAction<boolean>>;
	clickPosition: LatLng;
	windOrCurrent?: Wind | Current | null;
	isHidePopup?: boolean;
	setIsHidePopup?: (bool: boolean) => unknown;
}

const Popup = ({
	pointData,
	locationName,
	variableName,
	setShowGraph,
	clickPosition,
	windOrCurrent,
	isHidePopup,
	setIsHidePopup
}: PopupProps): JSX.Element => {
	const { map } = useContext(MapContext);

	const popupElemRef = useRef<HTMLDivElement>(null);
	const popupRef = useRef<Overlay | null>(null);
	const [renderPopup, setRenderPopup] = useState(false);

	// Create the popup
	useEffect(() => {
		if (map === null || popupElemRef.current === null) {
			return;
		}

		// Make the popup itself
		const popup = new Overlay({
			element: popupElemRef.current,
			autoPan: {
				animation: {
					duration: 250
				}
			},
			stopEvent: false
		});
		// Store a reference to it
		popupRef.current = popup;
		// Put it in the map
		map.addOverlay(popup);

		// Clean up everything when dismounting
		return () => {
			map.removeOverlay(popup);
			popup.dispose();
		};
	}, [map]);

	// Move the popup when props change
	// If pointData is null, then no data should be displayed
	const { lat: popupLat, lng: popupLng } = pointData === null ? clickPosition : pointData.position;
	useEffect(() => {
		// Get the popup and verify it exists
		const popup = popupRef.current;
		setRenderPopup(false);
		if (map === null || popup === null) {
			return;
		}
		popupRef;
		// Convert lat/lon position to the map's projection
		const position = proj.fromLonLat([popupLng, popupLat], map.getView().getProjection());
		// Update the popup's position
		popup.setPosition(position);
		setRenderPopup(true);
	}, [map, popupLng, popupLat]);

	const hidePopup = () => {
		// Get the popup and verify it exists
		const popup = popupRef.current;
		if (popup === null) {
			return;
		}

		// Hide it by setting its position to undefined
		popup.setPosition(undefined);

		// Hide the graph
		setShowGraph(false);
		nukePopup();
	};

	const nukePopup = () => {
		if (map === null || popupRef.current === null) {
			return;
		}

		map.removeOverlay(popupRef.current);
	};

	useEffect(() => {
		if (map === null || popupRef === null) {
			return;
		}

		if (isHidePopup && popupRef.current !== null) {
			map.removeOverlay(popupRef.current);
			if (setIsHidePopup) {
				setIsHidePopup(false);
			}
		}
	}, [isHidePopup, setIsHidePopup, map]);

	let value = pointData?.[variableName];

	if (value) {
		value = Math.round(value);
	}

	if (variableName === "wind" && windOrCurrent) {
		value = Math.round(windOrCurrent.strength);
	}

	if (variableName === "current" && windOrCurrent) {
		value = parseFloat(windOrCurrent.strength.toFixed(1));
	}

	const variableOption = MeasurementUnits[variableName];
	const symbol = variableOption.symbol;
	return (
		<div className={renderPopup ? "popup-header" : "d-none"}>
			<div ref={popupElemRef} className={`${styles["popup-box"]} ol-popup`}>
				<div className="ol-popup-close" onClick={hidePopup} role="button"></div>
				{pointData === null ? (
					<div className="flex">
						<div className={`text-align-center ${styles["popup-location-name"]}`}>{locationName}</div>
						<div className={`text-align-center ${styles["popup-location-name"]}`}>
							<span>Ingen Data</span>
						</div>
					</div>
				) : (
					<div className="flex">
						<div className={`text-align-center ${styles["popup-location-name"]}`}>{locationName}</div>
						<div className="text-align-center">
							{variableName === "current" || variableName === "wind" ? (
								<span className={`${styles["popup-variable"]}`}>
									{value}
									{symbol}
								</span>
							) : (
								<div>
									<span className={`${styles["popup-variable"]}`}>
										{value}
										{symbol}
									</span>
									<div className="text-align-center">
										<span
											className={`${styles["popup-details"]}`}
											onClick={() => setShowGraph(s => !s)}
											role="button"
										>
											Se detaljert varsel
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Popup;
export { Popup };
