import * as dateFns from "date-fns";
import settings from "../../../settings";

const Apiet = (): JSX.Element => {
	const publicationDate = new Date("2022-07-07T10:00:00+0200");

	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/code.jpg"} alt="" />
					{/* XXX: Image from here: https://pixabay.com/photos/code-html-digital-coding-web-1076536/ replace? */}
				</div>
				<div className="row mt-3">
					<div>API</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Havvarsel sitt API</h1>
						<div className="text-muted">
							Publisert{" "}
							<time dateTime={publicationDate.toISOString()}>
								{dateFns.format(publicationDate, "dd.MM.yyyy")}
							</time>
						</div>
					</div>
					<hr />
					<p>
						Les dokumentasjonen til Havvarsel sitt API{" "}
						<a href="https://api.havvarsel.no/apis/duapi/havvarsel/v2/swagger-ui.html">her</a>.
						{/*  Eksempler
						hvordan apiet brukes, finnes <a href="">her</a>. */}
						{/* TODO: Sett inn github-lenke til prosjektet med eksempler på api-kall når dette er klart. */}
					</p>
				</div>
			</article>
		</div>
	);
};

export default Apiet;
export { Apiet };
