import * as yup from "yup";

export const skrivemateJsonSchema = yup.object({
	navnestatus: yup.string().required(),
	skrivemåte: yup.string().required(),
	skrivemåtestatus: yup.string().required(),
	språk: yup.string().required(),
	stedsnavnnummer: yup.number().integer().required()
});

export type SkrivemateJson = yup.Asserts<typeof skrivemateJsonSchema>;
