import { GetProjection } from "./types/GetProjection";
import { PointData } from "../../types/PointData";
import { LatLng } from "../../types/LatLng";
import { backendToFrontend } from "./variableMaps";

const projectionToPointData = (projection: GetProjection): PointData[] => {
	// Figure out where this data is from
	const position: LatLng = {
		lat: projection.closestGridPointWithData.lat,
		lng: projection.closestGridPointWithData.lon
	};

	const pointDataMap = [...backendToFrontend.entries()]
		// Create one long array of all the data.
		// This results in an array { raw_time: number; value: number; variableName: string }[]
		.flatMap(([backendVarName, frontendVarName]) => {
			// Get the data for this variable
			const variableData =
				projection.variables.find(variable => variable.variableName === backendVarName)?.data ?? [];

			// Add the variable name to the data
			return variableData.map(data => ({ ...data, variableName: frontendVarName }));
		})
		// Turn the whole thing into a map from timestamps to data
		// This results in a Map<number, { temperature?: number; salinity?: number }>
		.reduce((m, datapoint) => {
			// Shorthand for the timestamp
			const ts = datapoint.rawTime;

			// Add the data to the map at that timestamp
			const existingData = m.get(ts) ?? ({ position, timestamp: new Date(ts) } as PointData);
			m.set(ts, { ...existingData, [datapoint.variableName]: datapoint.value });

			// Return the map for the next iteration
			return m;
		}, new Map<number, PointData>());

	// Convert the map into an array
	const pointData = [] as PointData[];
	for (const mapping of pointDataMap) {
		pointData.push({ ...mapping[1] });
	}

	// Sort the data by timestamp to get them in a nice order
	// XXX Array.prototype.sort is mutating the array! This doesn't matter in this case, but be aware of it!
	return pointData.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
};

export default projectionToPointData;
export { projectionToPointData };
