import React from "react";

type ErrorBoundaryProps = React.PropsWithChildren<{
	/** Function to call when an error is caught */
	onError?(error: Error, errorInfo: React.ErrorInfo): void;
	/** Fallback UI to render when an error is caught. Will receive the error and errorInfo as props */
	fallback: React.ReactElement<Record<string, unknown> & Partial<{ error: Error; errorInfo: React.ErrorInfo }>>;
}>;

type ErrorBoundaryState = {
	error: Error | null;
	errorInfo: React.ErrorInfo | null;
};

/**
 * Catches thrown errors from components further down the tree, and renders a fallback when it does
 */
// XXX Functional components cannot catch errors. This is one of the few things which must be a class component
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);

		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		// Update the state
		this.setState({ error, errorInfo });

		// Call the onError prop function if it exists
		this.props.onError?.(error, errorInfo);
	}

	render(): React.ReactNode {
		if (this.state.error !== null && this.state.errorInfo !== null) {
			const { error, errorInfo } = this.state;

			return React.Children.map(this.props.fallback, c => {
				// Extend the props of valid child elements, and not primitive DOM types
				if (React.isValidElement(c) && typeof c.type !== "string") {
					return React.cloneElement(c, { error, errorInfo });
				}

				// Return other child elements (strings, null, ...) as they are
				return c;
			});
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
export { ErrorBoundary };
