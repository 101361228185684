import * as dateFns from "date-fns";
import settings from "../../../settings";

const BadeTemperatur = (): JSX.Element => {
	const publicationDate = new Date("2021-07-01T10:00:00+0200");

	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/sjotemperatur.jpg"} alt="" />
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Hvorfor varierer badetemperaturen?</h1>
						<h5>Både vær og dypvann påvirker badetemperaturen.</h5>
						<div className="text-muted">
							Publisert{" "}
							<time dateTime={publicationDate.toISOString()}>
								{dateFns.format(publicationDate, "dd.MM.yyyy")}
							</time>
							<div>Tekst: Jon Albretsen</div>
						</div>
						<hr />
						<p>
							Sjøens overflatetemperatur – eller badetemperaturen – er hovedsakelig styrt av det lokale
							været. Det er til stor del solen som varmer opp badevannet om sommeren, mens vinden kan
							ødelegge gode badetemperaturer. Hvis dere har hatt fint vær med mye sol og har oppnådd gode
							badetemperaturer, vil sterk vind kunne blande overflatevannet med det kaldere vannet som
							ligger under. Hvis det i tillegg blir fralandsvind, så vil det varme overflatevannet drive
							vekk og bli erstattet med kaldere vann nedenfra. Hvis det er stor forskjell i temperaturen
							mellom overflatevannet og det som ligger 10–20 meter dypere, vil vindens påvirkning kunne
							endre badetemperaturen drastisk på veldig få timer. Sjøvann som kommer drivende fra nære
							eller fjerne områder vil også påvirke badetemperaturen lokalt, men ikke i så stor grad som
							solstråling, vind og lufttemperatur.
						</p>
					</div>
				</div>
			</article>
		</div>
	);
};

export default BadeTemperatur;
export { BadeTemperatur };
