import { VariableNames } from "../../types/VariableNames";
import styles from "./ColorBar.module.scss";
import MeasurementUnits from "./MeasurementUnits";

interface ColorBarProps {
	variableName: VariableNames; // Might not need
}

const variableOptions = {
	temperature: {
		title: "Temperature",
		values: [-2, 5.5, 13, 20.5, 28]
	},
	salinity: {
		title: "Saltholdighet",
		values: [0, 10, 20, 30, 40]
	},
	wind: {
		title: "Vindhastighet",
		values: [0, 5, 10, 15, 20]
	},
	current: {
		title: "Strømhastighet",
		values: [0, 0.1, 0.2, 0.3, 0.4, 0.5]
	}
} as const;

const ColorBar = ({ variableName }: ColorBarProps): JSX.Element | null => {
	const variableOption = variableOptions[variableName];
	const unit = MeasurementUnits[variableName];

	const opacity =
		variableName === "current" || variableName === "wind"
			? `${styles["image-z-index-negative"]}`
			: `${styles["image-z-index-positive"]}`;

	const getGradientClassName = () => {
		if (variableName === "current") {
			return `${styles["current-gradient"]}`;
		} else if (variableName === "wind") {
			return `${styles["wind-gradient"]}`;
		} else {
			return "";
		}
	};

	return (
		<div className={`${styles["colorbar-container"]}`}>
			<div className={`${styles["colorbar-values-properties"]}`}>
				{variableOption.values.map((value: number) => (
					<span key={value}>{value}</span>
				))}
			</div>
			<div className={`${styles["colorbar-wrapper"]}`}>
				<div className={`${getGradientClassName()} ${styles["pickgradient"]}`}>
					<img className={`w-100 ${opacity}`} src="/temperature-scale.png" />
				</div>
			</div>
			<div>{`${unit.symbol}`}</div>
		</div>
	);
};

export default ColorBar;
export { ColorBar };
