import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skrivemate } from "../../apis/geonorge/stedsnavn/types/Skrivemate";
import GeolocationButton from "./GeolocationButton";
import GeoNorgeNavnSearch from "./GeoNorgeNavnSearch";
import styles from "./PlaceSelector.module.scss";

interface PlaceSelectorProps {
	onNavnResult: (result: Skrivemate) => unknown;
	onGeolocation: (geolocation: GeolocationPosition) => unknown;
	onGeolocationError: (error: GeolocationPositionError) => unknown;
	defaultValue?: string;
}

const PlaceSelector = ({
	onNavnResult,
	onGeolocation,
	onGeolocationError,
	defaultValue
}: PlaceSelectorProps): JSX.Element => (
	<div className={`input-group ${styles["round-edges"]}`}>
		<GeoNorgeNavnSearch onResult={onNavnResult} defaultValue={defaultValue} />
		<GeolocationButton onGeolocation={onGeolocation} onError={onGeolocationError} className="btn btn-light">
			<FontAwesomeIcon icon={faCrosshairs} />
		</GeolocationButton>
	</div>
);

export default PlaceSelector;
export { PlaceSelector };
