import { GetProjection, getProjectionSchema } from "../../../apis/backend/types/GetProjection";
import { VariableNames } from "../../../types/VariableNames";
import { useEffect, useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { variableOptionsHeatMap } from "./VariableOptions";

// Depth in meters: In	dex in array corresponds to depthIndex
const depthsInMeters = [0, 3, 10, 15, 25, 50, 75, 100, 150, 200, 250, 300, 500, 1000, 2000, 3000];

interface HeatChartProps {
	variable: VariableNames;
	dataForGraph: GetProjection[];
}

const HeatChart = ({ variable, dataForGraph }: HeatChartProps): JSX.Element => {
	// Initialize the 3D module
	HighchartsHeatmap(Highcharts);
	// Reference to the element to render the chart into
	const chartElemRef = useRef<HTMLDivElement>(null);

	// Filter all data from current time and after
	// Turn the data into x/y/z format
	// Function to filter an array of GetProjection based on rawTime
	const filterProjectionsByRawTime = (projections: GetProjection[]): GetProjection[] => {
		const currentTime = new Date().getTime();
		const variables = variable;

		// Use yup to validate and filter the array of objects
		const filteredProjections = projections.map(projection =>
			getProjectionSchema.validateSync(projection, { stripUnknown: true })
		);

		// Filter out projections that are not in the future
		return filteredProjections.map(filteredProjection => ({
			...filteredProjection,
			variables: filteredProjection.variables
				.filter(variable => variables.includes(variable.variableName))
				.map(variable => ({
					...variable,
					data: variable.data.filter(dataPoint => dataPoint.rawTime > currentTime)
				}))
		}));
	};
	// Function to map rawTimes and values to x, y and z coordinates
	const mapToCoordinates = (projections: GetProjection[]): { x: number; y: number; value: number }[] => {
		const coordinates: { x: number; y: number; value: number }[] = [];

		projections.forEach((projection, depth) => {
			projection.variables.forEach(variable => {
				variable.data.forEach(dataPoint => {
					coordinates.push({
						x: dataPoint.rawTime,
						value: Number(dataPoint.value.toFixed(2)),
						y: depth
					});
				});
			});
		});
		return coordinates;
	};
	// Data to be displayed in the graph
	const data = mapToCoordinates(filterProjectionsByRawTime(dataForGraph));
	// Sort the data based on depths as promises might be returned unordered
	data.sort((a, b) => a.x.toFixed().localeCompare(b.x.toFixed()) || a.y - b.y);
	//const uniqueTimes = Array.from(new Set(data.map(point => point.x)));
	const uniqueDepths: number[] = Array.from(new Set(data.map(point => point.y)));
	// Convert depths from index to meters for Y axis in chart, make them strings and attach "m"
	const depthsForYaxis = depthsInMeters.slice(0, uniqueDepths.length).map(number => `${number}m`);

	// Set chart config
	const variableOptionHeatMap = variableOptionsHeatMap[variable];
	const series = [
		{
			colsize: 36e5,
			rowsize: 1,
			data: data,
			boostThreshold: 100,
			borderWidth: 0,
			name: variableOptionHeatMap.title,
			tooltip: {
				pointFormat: variableOptionHeatMap.tooltipPointFormat
			}
		}
	];
	const chartOptions: Highcharts.Options = {
		chart: {
			scrollablePlotArea: {
				// Enable scrolling on mobile
				minWidth: 500,
				scrollPositionX: 0
			},
			type: "heatmap",
			backgroundColor: "#E3F3FB",
			height: "100%",
			marginLeft: 50
		},
		accessibility: {
			enabled: false
		},
		credits: {
			enabled: false // Whether to include highcharts.com watermark or not
		},
		time: {
			useUTC: false // If true, it displays GMT
		},
		boost: {
			useGPUTranslations: true
		},
		title: {
			text: ""
		},
		yAxis: {
			offset: 0,
			categories: depthsForYaxis,
			reversed: true,
			title: {
				align: "high",
				text: variableOptionHeatMap.YLabelText,
				style: {
					"text-anchor": "start",
					fontSize: "12px"
				},
				rotation: 0,
				y: -5
				// x: -25
			},
			labels: {
				style: {
					fontSize: "10px"
				}
			}
		},
		xAxis: [
			{
				// Bottom X axis
				tickInterval: 3600 * 1000 * 2,
				minorTickInterval: 36e5,
				tickLength: 0,
				gridLineWidth: 1,
				gridLineColor: "rgba(128, 128, 128, 0.1)",
				startOnTick: false,
				endOnTick: false,
				minPadding: 0,
				maxPadding: 0,
				showLastLabel: true,
				labels: {
					x: 10,
					format: "{value:%H}" // Bottom X axis hours
				},
				crosshair: true
				// width: 1000 // Sets width of the X Axis
			},
			{
				// Top X axis
				linkedTo: 0,
				tickInterval: 24 * 3600 * 1000,
				labels: {
					format: '{value:<span style="font-size: 12px; font-weight: bold">%a</span> %b %e}' // TODO: Fix this so we get norwegian / english date at the top of the X axis
				},
				opposite: true,
				tickLength: 20,
				gridLineWidth: 1,
				gridLineColor: "#000000",
				lineWidth: 2
			}
		],
		legend: {},
		colorAxis: {
			min: Math.min(...data.map(point => point.value)),
			max: Math.max(...data.map(point => point.value)),
			stops: [
				[0, "#3060cf"],
				[0.5, "#fffbbc"],
				[1, "#c4463a"]
			],
			labels: {
				format: "{value}"
			}
		},
		series: series as unknown as Array<Highcharts.SeriesOptionsType>
	};
	// Set up the map when the component has rendered, and whenever it rerenders
	useEffect(() => {
		// Don't do anything if there is no element to render the chart into
		if (chartElemRef.current === null) {
			return;
		}
		// Render the chart into the given DOM element
		const chart = Highcharts.chart(chartElemRef.current, chartOptions, undefined);

		return () => {
			// Clean up the chart
			chart.destroy();
		};
	});

	return (
		<div className="chart-container">
			<div className="chart-width" ref={chartElemRef}></div>
		</div>
	);
};

export default HeatChart;
