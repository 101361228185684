import { LatLng } from "../../types/LatLng";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Skrivemate } from "../../apis/geonorge/stedsnavn/types/Skrivemate";
import * as backend from "../../apis/backend";
import * as geonorgeStedsnavn from "../../apis/geonorge/stedsnavn";
import * as dateFns from "date-fns";
import Watermark from "../misc/Watermark";
import PlaceSelector from "../misc/PlaceSelector";
import WMSLayer from "../map/layers/WMSLayer";
import WindAndCurrentLayer from "../map/layers/WindAndCurrentLayer";
import Layers from "../map/layers/Layers";
import Popup from "../map/layers/Popup";
import ClickPosition from "../map/controls/ClickPosition";
import MapComponent from "../map/Map";
import Controls from "../map/controls/Controls";
import { PointData } from "../../types/PointData";
import {
	translateVariableNames,
	getLayerForVariable,
	layerNameToFrontendVarName
} from "../../apis/backend/variableMaps";
import { variableNames } from "../../types/VariableNames";
import OSMLayer from "../map/layers/OSMLayer";
import styles from "./Map.module.scss";
import LayerSelectorControlContainer from "../map/controls/LayerSelectorControlContainer";
import ColorBar from "../misc/ColorBar";
import DisplayData from "../displaydata/DisplayData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import NoRotation from "../map/NoRotationHack";
import { savePlace } from "../../types/Place";
import { containsExtent, Extent } from "ol/extent";
import { WindAndCurrent } from "../../apis/backend/types/GetWindAndCurrent";
import { Current, Wind } from "../../apis/backend/types/Wind";
import increaseExtent from "../misc/IncreaseExtent";
import settings from "../../settings";

interface MapProps {
	/** Center position of the map */
	position: LatLng;
	/** Zoom level of the map */
	zoom: number;
	/** Callback to set the center and zoom level */
	setPosZoom: (newPos: LatLng, newZoom: number) => unknown;
	/** Handler for clicks on the back button */
	//onBack: () => unknown;
}

const SEARCH_ZOOM_LEVEL = 10;

// const Map = ({ position, zoom, setPosZoom, onBack }: MapProps): JSX.Element => {
const MapPage = ({ position, zoom, setPosZoom }: MapProps): JSX.Element => {
	const navigate = useNavigate();
	// Keep track of which map layer is active, and which layers exist
	const [availableLayers, setAvailableLayers] = useState<string[]>([]);
	const [activeLayer, setActiveLayer] = useState<string | undefined>();
	const [wmsUrl, setWmsUrl] = useState<string | null>(null);
	const [windAndCurrentPolygon, setWindAndCurrentPolygon] = useState<string | null>(null);

	const windOrCurrentLayer =
		activeLayer === getLayerForVariable("wind", availableLayers) ||
		activeLayer === getLayerForVariable("current", availableLayers);

	useEffect(() => {
		// Keep track of whether or not the component is mounted
		let isMounted = true;

		void (async () => {
			const { layerNames, layerUrl } = await backend.wmsList();

			// Abort if the component has unmounted
			if (!isMounted) {
				return;
			}

			// Put the data on the state
			setAvailableLayers(layerNames);
			setWmsUrl(layerUrl);

			// Make the temperature layer active, or the first one if no temperature layer can be found
			const temperatureLayer = getLayerForVariable("temperature", layerNames) ?? layerNames[0];
			setActiveLayer(temperatureLayer);

			const windAndCurrentPolygon = await backend.fetchWindAndCurrentPolygon();
			setWindAndCurrentPolygon(windAndCurrentPolygon);
		})();

		return () => {
			isMounted = false;
		};
	}, []);

	// Figure out which variable the layer corresponds to
	const activeVariable = activeLayer === undefined ? undefined : layerNameToFrontendVarName.get(activeLayer);

	// Keep track of the position and its data
	const [clickPosition, setClickPosition] = useState<LatLng | null>(null);
	const [dataPositionName, setDataPositionName] = useState<string | null>(null);
	const [pointData, setPointData] = useState<PointData[] | null>(null);
	const [renderPopup, setRenderPopup] = useState(false);
	const [closestGridPoint, setClosestGridPoint] = useState<{ lat: number; lon: number } | null>(null);
	const [closestWindData, setClosestWindData] = useState<Wind | null>(null);
	const [closestCurrentData, setClosestCurrentData] = useState<Current | null>(null);
	const [mapRectangle, setMapRectangle] = useState<Extent | null>(null);
	const [mapRectangleCache, setMapRectangleCache] = useState<Extent>([0, 0, 0, 0]);
	const [windAndCurrentData, setWindAndCurrentData] = useState<WindAndCurrent | null>(null);
	const [windAndCurrentDataCache, setWindAndCurrentDataCache] = useState<WindAndCurrent | null>(null);

	// Fetch data for the clicked position
	const { lat: clickLat, lng: clickLng } = clickPosition ?? {};
	useEffect(() => {
		// Don't do anything without a position
		if (clickLat === undefined || clickLng === undefined) {
			return;
		}

		// Keep track of the component's mounted status
		let isMounted = true;

		// Clear the data while new data is being fetched
		setPointData(null);
		setDataPositionName(null);
		setRenderPopup(false);

		void (async () => {
			let placePos = null;
			try {
				const after = dateFns.startOfDay(new Date());
				const before = dateFns.add(after, { hours: 72 });
				const pointData = await backend.fetchPointData(
					{
						after,
						before,
						lat: clickLat,
						lon: clickLng,
						variable: [...variableNames]
					},
					setClosestGridPoint
				);
				// Bail out if the component has unmounted
				if (!isMounted) {
					return;
				}
				placePos = pointData[0].position;

				// Store the data
				setPointData(pointData);
			} catch (e) {
				placePos = { lat: clickLat, lng: clickLng };
			}

			// Get the name of the data's position
			// pos possibly null
			const placenames = await geonorgeStedsnavn.punkt({
				nord: placePos.lat,
				ost: placePos.lng,
				koordsys: 4326
			});

			// Bail out if the component has unmounted
			if (!isMounted) {
				return;
			}
			// Store the place name
			setDataPositionName(placenames.navn[0]?.stedsnavn[0]?.skrivemåte ?? null);
			setRenderPopup(true);
		})();

		return () => {
			isMounted = false;
		};
	}, [clickLat, clickLng]);

	/** Sets both click position and map position to the given point */
	const setFocusPosition = (newPos: LatLng, newZoom?: number) => {
		setClickPosition(newPos);
		setPosZoom(newPos, newZoom ?? zoom);
	};

	/** Handles results from the place name search */
	const handleNavnResult = (data: Skrivemate) => {
		// Convert the result to a place object
		const place = geonorgeStedsnavn.skrivemåteToPlace(data);

		// Save its position to state
		setFocusPosition(place.position, SEARCH_ZOOM_LEVEL);

		// Navigate to the result position
		navigate(`/bade/${place.position.lat}/${place.position.lng}/${SEARCH_ZOOM_LEVEL}`, { replace: true });

		// Save the result
		savePlace(place);
	};

	/** Handles clicks in the map */
	const handleMapClick = (pos: LatLng) => {
		// Don't do anything if there is no active layer
		if (activeVariable === undefined) {
			return;
		}
		// Store the position
		setFocusPosition(pos);

		//navigate to the position
		navigate(`/bade/${pos.lat}/${pos.lng}/${zoom}`, { replace: true });
	};

	/** Handles the positive result from getting the geolocation */
	const handleGeolocation = (geolocation: GeolocationPosition): void => {
		const pos = {
			lat: geolocation.coords.latitude,
			lng: geolocation.coords.longitude
		};
		setFocusPosition(pos, SEARCH_ZOOM_LEVEL);

		// Navigate to the geo position
		//TODO, move navigation to useMapNavigation
		navigate(`/bade/${pos.lat}/${pos.lng}/${SEARCH_ZOOM_LEVEL}`, { replace: true });
	};

	/** Handles errors when getting geolocation */
	const handleGeolocationError = (err: GeolocationPositionError): void => {
		/* TODO */
		if (err.message.toLowerCase() === "user denied geolocation") {
			// If permission to get location is disabled
			alert("Fikk ikke tillatelse til å hente din posisjon");
		} else {
			alert("Kunne ikke hente din posisjon");
		}
	};

	// Keep track of whether or not the graph should display
	const [showGraph, setShowGraph] = useState<boolean>(false);

	// Get the data for right now for the popup
	const now = new Date();
	const popupData =
		activeVariable === undefined
			? null
			: pointData?.find(({ timestamp }) => dateFns.isSameHour(timestamp, now)) ?? null;

	const [isHidePopup, setIsHidePopup] = useState<boolean>(false);

	useEffect(() => {
		if (renderPopup && windOrCurrentLayer && zoom < SEARCH_ZOOM_LEVEL) {
			setIsHidePopup(true);
		}
	}, [zoom, renderPopup, windOrCurrentLayer]);

	// Prevent new function created every time, by using useCallback
	const updateWindAndCurrentClickData = useCallback(() => {
		if (windAndCurrentData) {
			const clickWindAndCurrent = windAndCurrentData.data.filter(
				data => data.coords.lat === closestGridPoint?.lat && data.coords.lon === closestGridPoint.lon
			);

			if (clickWindAndCurrent[0]) {
				setClosestWindData(clickWindAndCurrent[0].wind);
				setClosestCurrentData(clickWindAndCurrent[0].current);
			}
		}
	}, [closestGridPoint, windAndCurrentData]);

	// Prevent new function created every time, by using useCallback
	const setLatLongRectangle = useCallback((childdata: Extent | null) => {
		setMapRectangle(childdata);
	}, []);

	// When there is no rectangle, no windAndCurrentData should be loaded. The last data fetched will be cached.
	useEffect(() => {
		if (mapRectangle === null) {
			if (windAndCurrentData) {
				setWindAndCurrentDataCache(windAndCurrentData);
			}
			setWindAndCurrentData(null);
		}
	}, [mapRectangle, windAndCurrentData]);

	// Control when cached data is shown. This is for when a user zooms further out than the min zoom level for wind and current, the arrows will reappear when it is zoomed back in.
	useEffect(() => {
		if (windAndCurrentDataCache && mapRectangle && containsExtent(mapRectangleCache, mapRectangle)) {
			setWindAndCurrentData(windAndCurrentDataCache);
		}
	}, [mapRectangle, mapRectangleCache, windAndCurrentDataCache]);

	// When user zooms / move, update rectangle
	useEffect(() => {
		void (async () => {
			if (windOrCurrentLayer) {
				setShowGraph(false); // No graphs should be shown for wind & current
				// checks if new rectangle (after moving on map) is inside the cached recangle.
				if (mapRectangle && !containsExtent(mapRectangleCache, mapRectangle)) {
					setWindAndCurrentDataCache(null);
					// Fetch data for a slightly larger rectangle than what the screen represents. (to be able to move around sligtly without triggering a new request)
					const increasedRectangle = increaseExtent(mapRectangle, 0.3);
					const dateNow = new Date().toISOString();
					const params = {
						lonBottomLeft: increasedRectangle[0],
						latBottomLeft: increasedRectangle[1],
						lonTopRight: increasedRectangle[2],
						latTopRight: increasedRectangle[3],
						dateNow: dateNow
					};

					// TODO: Clicking on another arrow doesn't render all arrows. This gets run too early
					const data = await backend.fetchWindAndCurrentData(params);
					setWindAndCurrentData(data);
					// Remeber this recangle so new request are only triggered when moving outside of the rectangle.
					setMapRectangleCache(increasedRectangle);
				}
			}
		})();
	}, [mapRectangle, mapRectangleCache, windOrCurrentLayer]);

	useEffect(() => {
		updateWindAndCurrentClickData();
	}, [windAndCurrentData, updateWindAndCurrentClickData]);

	return (
		<div className={styles["map-page"]}>
			{/* The back bar */}
			<div className={`py-1 px-2 background-primary-one ${styles["back-bar"]}`}>
				<Link to="/" className="navbar-brand">
					<span>
						<FontAwesomeIcon icon={faAngleLeft} />
						<img
							src={settings.PUBLIC_URL + "/logos/havvarsel.svg"}
							className={`main-logo-size ${styles["back-bar-font-size"]}`}
						/>
					</span>
				</Link>
			</div>
			<div className={styles["map-box"]}>
				{/* The map */}
				<MapComponent
					zoom={zoom}
					center={position}
					onMoveEnd={setPosZoom}
					setLatLongRectangle={setLatLongRectangle}
				>
					<NoRotation />
					<Layers>
						<OSMLayer zIndex={0} />
						{activeLayer !== undefined ? (
							<React.Fragment>
								{wmsUrl !== null ? (
									<React.Fragment>
										{windOrCurrentLayer ? (
											<WindAndCurrentLayer
												zIndex={2}
												layerName={activeLayer}
												windAndCurrentData={windAndCurrentData}
												windAndCurrentPolygon={windAndCurrentPolygon}
												availableLayers={availableLayers}
											/>
										) : (
											<WMSLayer zIndex={1} url={wmsUrl} layerName={activeLayer} opacity={0.7} />
										)}
									</React.Fragment>
								) : null}
								{clickPosition && renderPopup && activeVariable ? (
									<Popup
										locationName={dataPositionName}
										variableName={activeVariable}
										pointData={popupData}
										setShowGraph={setShowGraph}
										clickPosition={clickPosition}
										windOrCurrent={
											activeLayer === "havvarsel_wind" ? closestWindData : closestCurrentData
										}
										isHidePopup={isHidePopup}
										setIsHidePopup={setIsHidePopup}
									/>
								) : null}
							</React.Fragment>
						) : null}
					</Layers>
					{windOrCurrentLayer && zoom < SEARCH_ZOOM_LEVEL && activeVariable ? (
						<div>
							<Watermark
								displaytext={`Zoom inn for å se ${translateVariableNames.get(activeVariable) ?? ""}`}
								styleClass="watermark-center"
								capitalLetter={false}
							/>
						</div>
					) : null}
					<Controls>
						<ClickPosition onPosition={handleMapClick} />
					</Controls>
				</MapComponent>
				{/* The side bar */}
				<div className={`px-2 pt-2 ${styles["sidebar"]}`}>
					<div className="mb-2 background-color-white">
						<div className={`${styles["layer-navigation-wrapper"]}`}>
							<LayerSelectorControlContainer
								availableLayers={availableLayers}
								setActiveLayer={setActiveLayer}
								activeLayer={activeLayer}
							/>
						</div>
						<div className={styles["colorbar-container"]}>
							{activeVariable !== undefined ? (
								<ColorBar variableName={activeVariable} />
							) : (
								<p className="text-center">Ingen kartlag valgt</p>
							)}
						</div>
					</div>
					<div className="mb-2">
						<PlaceSelector
							onNavnResult={handleNavnResult}
							onGeolocation={handleGeolocation}
							onGeolocationError={handleGeolocationError}
						/>
					</div>
					{clickPosition !== null &&
					popupData !== null &&
					pointData !== null &&
					activeVariable !== undefined &&
					showGraph ? (
						<div className={styles["display-data"]}>
							<DisplayData
								variableName={activeVariable}
								locationName={dataPositionName}
								pointData={pointData}
								setShowGraph={setShowGraph}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default MapPage;
export { MapPage as Map };
