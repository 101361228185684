import { useState, useEffect } from "react";
import Layers from "../map/layers/Layers";
import WMSLayer from "../map/layers/WMSLayer";
import { LatLng } from "../../types/LatLng";
import styles from "./TableData.module.scss";
import "ol/ol.css";
import OSMLayer from "../map/layers/OSMLayer";
import { generatePath, Link } from "react-router-dom";
import Map from "../map/Map";
import * as backend from "../../apis/backend";
import MapHack from "../map/MapHack";

interface SuggestedPlaceMap {
	position: LatLng;
}

/** Default zoom level of the map */
const ZOOM = 12;

// This is another map component. Display get_projection on it, should not be able to scroll.
const SuggestedPlaceMap = ({ position }: SuggestedPlaceMap): JSX.Element => {
	const [activeLayer, setActiveLayer] = useState<string | undefined>();
	const [wmsLayerUrl, setWmsLayerUrl] = useState<string | undefined>();

	useEffect(() => {
		// Set default layer
		const runAsync = async () => {
			const { layerNames: availableLayers, layerUrl } = await backend.wmsList();
			setActiveLayer(availableLayers[0]);
			setWmsLayerUrl(layerUrl);
		};
		void runAsync();
	}, []);

	return (
		<div className={styles["map-container"]}>
			<Link
				to={generatePath("/bade/:lat/:lng/:zoom", {
					lat: String(position.lat),
					lng: String(position.lng),
					zoom: String(ZOOM)
				})}
			>
				<Map center={position} zoom={ZOOM}>
					<MapHack />
					<div className={`${styles["explore-map"]}`}>
						<button className={`btn ${styles["explore-map-button"]}`}>Utforsk Kartet</button>
					</div>
					<Layers>
						<OSMLayer zIndex={0} />
						{wmsLayerUrl !== undefined && activeLayer !== undefined ? (
							<WMSLayer zIndex={1} url={wmsLayerUrl} layerName={activeLayer} />
						) : null}
					</Layers>
				</Map>
				<div className={`px-2 ${styles["layer-display"]}`}>Viser: Sjøtemperatur</div>
				<div className={`px-2 ${styles["today-label"]}`}>I dag</div>
			</Link>
		</div>
	);
};

export default SuggestedPlaceMap;
export { SuggestedPlaceMap };
