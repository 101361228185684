import * as dateFns from "date-fns";
import settings from "../../../settings";

const Strom = (): JSX.Element => {
	const publicationDate = new Date("2022-07-01T10:00:00+0200");

	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/strom.jpg"} alt="" />
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Hvordan varierer strømforholdene?</h1>
						<h5>
							Både vind, tidevann, elveavrenning og strømsystemene ute i havet påvirker strømmene i
							overflaten langs kysten vår.{" "}
						</h5>
						<div className="text-muted">
							Publisert{" "}
							<time dateTime={publicationDate.toISOString()}>
								{dateFns.format(publicationDate, "dd.MM.yyyy")}
							</time>
							<div>Tekst: Jon Albretsen og J. Even Ø. Nilsen</div>
						</div>
					</div>
					<hr />
					<p>
						Vinden drar på vannmassene, og jo sterkere den er, jo kraftigere vil strømmen bli. I trange
						fjorder og sund samles luftmassene, og vinden blir forsterket – strømmen likeså.
					</p>
					<p>
						Tidevannet beveger seg som en veldig lang bølge langs kysten vår (ca. 12 timer mellom høyvann og
						lavvann). På bølgens vei fra sør til nord strømmer vann inn og ut av fjorder og presses gjennom
						sund og streder. Noen steder langs norskekysten, f.eks. Saltstraumen og Moskstraumen, kan
						tidevannsstrømmene bli så sterke som 10–20 meter per sekund.
					</p>
					<p>
						Elver bidrar med ferskvann som legger seg i overflaten siden det er lett, og derfor strømmer
						vannet fra elven bort fra elvemunningen. Jo mer elveavrenning, jo sterkere strømmer ved
						elvemunninger og ut gjennom fjorder.
					</p>
					<p>
						Den norske kyststrømmen starter innerst i Skagerrak og følger kysten nordover, hele veien til
						Barentshavet. Den består av ferskvann fra våre egne og de andre nordeuropeiske elvene, blandet
						med saltholdig atlanterhavsvann. Det oppstår hele tiden virvler og buktninger i strømmen på
						grunn av bunnforhold, vær og vind.
					</p>
					<p>
						Hvilke krefter som har størst betydning varierer fra sted til sted. Hvis det blåser kraftig, vil
						vinden påvirke overflatestrømmene mest. Men hvis det er helt rolige vindforhold og lite
						avrenning fra nærliggende elver, er det kanskje tidevannsstrømmene som betyr mest.
					</p>
				</div>
			</article>
		</div>
	);
};

export default Strom;
export { Strom };
