import React from "react";

interface GeolocationButtonProps extends Record<string, unknown> {
	onGeolocation: (geolocation: GeolocationPosition) => unknown;
	onError: (error: GeolocationPositionError) => unknown;
	children: React.ReactNode;
}

const GeolocationButton = ({
	onGeolocation,
	onError,
	children,
	...buttonProps
}: GeolocationButtonProps): JSX.Element => {
	const fetchGeolocation = () => {
		navigator.geolocation.getCurrentPosition(onGeolocation, onError);
	};

	return (
		<button {...buttonProps} type="button" onClick={fetchGeolocation}>
			{children}
		</button>
	);
};

export default GeolocationButton;
export { GeolocationButton };
