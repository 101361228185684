import React from "react";
import styles from "./TableData.module.scss";

interface CurrentTemperatureProps {
	currentTemperature: number;
}

const PHRASES = [
	{
		below: 16,
		phrases: [
			"Brrrr!",
			"Hakkkkke tennnner.",
			"Aner vi neglesprett?",
			"Huttetu!",
			"Klar for en iskald opplevelse?",
			"De sier isbading er sunt.",
			"Fritt for ikke å bade!!",
			"Freskt! ",
			"Ekte vikinger fryser aldri",
			"Forfriskende",
			"Oooooo, det var kaldt…",
			"Jeg fryser på meg brokk!",
			"Fy Fabian!",
			"Pust og pes!",
			"Hekkan!",
			"Førstemann opp igjen!"
		]
	},
	{
		below: 20,
		phrases: [
			"En helt vanlig norsk badetemperatur.",
			"Helt grei norsk badetemperatur.",
			"Noen dager med sol nå, så snakker vi!",
			"Klar for en dukkert?",
			"Benytt sjansen. Det kan være kaldere i vannet i morgen.",
			"Det blir sjelden bedre enn dette",
			"Oooooo, det var kaldt…",
			"Nå nærmer det seg",
			"Der ja!",
			"Ålreit dette",
			"Håper det blir bedre"
		]
	},
	{
		below: Infinity,
		phrases: [
			"Jøss, sjekk den badetemperaturen. Akkurat som i Syden!",
			"I dag er det bare plumpe uti og kose seg.",
			"Hvis du ikke har badet i sommer er dette dagen.",
			"Førstemann uti!",
			"Sistemann uti er en sjøkuuuuuuu!",
			"Slike badetemperaturer kan vi bli vant til.",
			"Da er isbadesesongen over",
			"Frem med speedo og badehette",
			"Oooooo, her kan til og med fisken kose seg…",
			"Blikke bedre enn detta!",
			"Bosetter meg her, jeg. Kast uti noe å spise’a.",
			"Å, så digg!",
			"Deilig!",
			"Kom uti, da vel!",
			"Hent en smoothie til meg!"
		]
	}
];

const CurrentTemperature = ({ currentTemperature }: CurrentTemperatureProps): JSX.Element => {
	// Find the phrase array to use
	const phraseArr = PHRASES.find(({ below }) => below >= currentTemperature)?.phrases ?? [
		"Det er temperatur i vannet"
	];
	const phrase = phraseArr[Math.floor(Math.random() * phraseArr.length)];

	return (
		<React.Fragment>
			<p className={`${styles["current-temperature-font-size"]} mb-0`}>
				{Math.round(currentTemperature).toFixed(0)} °C
			</p>
			<p className={`${styles["current-temperature-font-size-description"]} mt-0`}>{phrase}</p>
		</React.Fragment>
	);
};

export default CurrentTemperature;
export { CurrentTemperature };
