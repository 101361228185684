import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { useContext, useEffect, useMemo } from "react";
import MapContext from "../MapContext";
import TileSource from "ol/source/Tile";

interface WMSLayerProps {
	/** URL to the WMS server */
	url: string;
	/** WMS layer to use */
	layerName: string;
	/** Where in the layer stack this layer should be placed */
	zIndex: number;
	/** Opacity of the layer, as a value between 0 and 1, inclusive. Defaults to 1 */
	opacity?: number;
}

const WMSLayer = ({ url, layerName, zIndex, opacity }: WMSLayerProps): null => {
	// Extract the map from the context
	const { map } = useContext(MapContext);
	// Store the layer between runs
	const layer = useMemo<TileLayer<TileSource>>(
		() =>
			new TileLayer({
				source: new TileWMS({
					url,
					params: {
						LAYERS: layerName
					}
				})
			}),
		[url, layerName]
	);

	// Handle the props
	layer.setOpacity(opacity ?? 1);
	layer.setZIndex(zIndex);

	// Add it to the map
	useEffect(() => {
		map?.addLayer(layer);

		return () => {
			map?.removeLayer(layer);
		};
	}, [map, layerName, layer]);

	return null;
};

export default WMSLayer;
export { WMSLayer };
