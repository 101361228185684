import { StrictMode } from "react";
import { Router } from "./components/router/Router";
import { I18nextProvider } from "react-i18next";
import { i18next } from "./i18n";
import TosModal from "./components/misc/Modal";

const App = (): JSX.Element => (
	<StrictMode>
		<I18nextProvider i18n={i18next}>
			<TosModal />
			<Router />
		</I18nextProvider>
	</StrictMode>
);

export default App;
