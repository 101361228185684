import { useMemo } from "react";
import OSM, { Options } from "ol/source/OSM";
import TileLayer from "./TileLayer";

interface OSMLayerProps {
	zIndex: number;
	options?: Options;
}

const OSMLayer = ({ zIndex, options }: OSMLayerProps): JSX.Element => {
	const source = useMemo<OSM>(() => new OSM(options), [options]);

	return <TileLayer zIndex={zIndex} source={source} />;
};

export default OSMLayer;
export { OSMLayer };
