import { useEffect, useContext } from "react";
import MapContext from "../MapContext";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { LatLng } from "../../../types/LatLng";
import * as proj from "ol/proj";

interface ClickPositionProps {
	onPosition: (pos: LatLng) => void;
}

const ClickPosition = ({ onPosition }: ClickPositionProps): null => {
	// Get the map from the context
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (map === null) {
			return;
		}

		const mapClickPosition = (e: MapBrowserEvent<PointerEvent>) => {
			// Clicking on map gives us a canvas, if it is a canvas try to find temperature
			// XXX Does not work on phone, need to do something else here
			const shouldFindNewPosition: boolean = e.originalEvent.target instanceof HTMLCanvasElement;
			if (!shouldFindNewPosition) {
				// Clicked on the popup or close button, not on the map itself
				return;
			}

			// Transform the position from the map's projection to lat/lon
			const position = proj.toLonLat(e.coordinate, map.getView().getProjection());

			// Call the handler
			onPosition({ lat: position[1], lng: position[0] });
		};

		// Add click listener
		map.on("click", mapClickPosition);

		return () => {
			// Remove click listener
			map.un("click", mapClickPosition);
		};
	}, [map, onPosition]);

	return null;
};

export default ClickPosition;
export { ClickPosition };
