/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "../layout/Layout";
import Frontpage from "../pages/Frontpage";
import NotFound from "../pages/NotFound";
import Havet from "../pages/about/Havet";
import SoettOgSalt from "../pages/about/SoettOgSalt";
import HavvarselForskjell from "../pages/about/HavvarselForskjell";
import SjoeTemperatur from "../pages/about/SjoeTemperatur";
import Saltholdighet from "../pages/about/Saltholdighet";
import Vind from "../pages/about/Vind";
import Strom from "../pages/about/Strom";
import Havvarsel from "../pages/about/Havvarsel";
import SlikBrukesHavvarsel from "../pages/about/SlikBrukesHavvarsel";
import KommerPaaHavvarsel from "../pages/about/KommerPaaHavvarsel";
import Prosjektet from "../pages/about/Prosjektet";
import Apiet from "../pages/about/Apiet";
import MapRoute from "./MapRoute";
import OverviewRoute from "./OverviewRoute";

export const routes: RouteObject[] = [
	{
		element: (
			<Layout>
				<Outlet />
			</Layout>
		),
		children: [
			{
				path: "/",
				element: <Frontpage />
			},
			{
				path: "/oversikt/:lat/:lng",
				element: <OverviewRoute />
			},
			{
				path: "/om/havet",
				element: <Havet />
			},
			{
				path: "/om/soettogsalt",
				element: <SoettOgSalt />
			},
			{
				path: "/om/sjoetemperatur",
				element: <SjoeTemperatur />
			},
			{
				path: "/om/vind",
				element: <Vind />
			},
			{
				path: "/om/saltholdighet",
				element: <Saltholdighet />
			},
			{
				path: "/om/strom",
				element: <Strom />
			},
			{
				path: "/om/havvarselforskjell",
				element: <HavvarselForskjell />
			},
			{
				path: "/om/slikbrukeshavvarsel",
				element: <SlikBrukesHavvarsel />
			},
			{
				path: "/om/kommerpaahavvarsel",
				element: <KommerPaaHavvarsel />
			},
			{
				path: "/om/prosjektet",
				element: <Prosjektet />
			},
			{
				path: "/om/apiet",
				element: <Apiet />
			},
			{
				path: "/om/havvarsel",
				element: <Havvarsel />
			},
			{
				path: "*",
				element: <NotFound />
			}
		]
	},
	{
		path: "/bade/:lat/:lng/:zoom",
		element: <MapRoute />
	}
];

export const Router = () => <RouterProvider router={createBrowserRouter(routes)} />;
export default Router;
