import React, { useEffect, useState } from "react";
import * as dateFns from "date-fns";
import SelectDay from "../tables/SelectDay";
import PlaceSelector from "../misc/PlaceSelector";
import CurrentTemperature from "../tables/CurrentTemperature";
import HourlyTableData from "../tables/HourlyTableData";
import SuggestedPlaceMap from "../tables/SuggestedPlaceMap";
import NewsArticlePreview from "../misc/NewsArticlePreview";
import { LatLng } from "../../types/LatLng";
import { Skrivemate } from "../../apis/geonorge/stedsnavn/types/Skrivemate";
import { PointData } from "../../types/PointData";
import { AvailableDays } from "../../types/AvailableDays";
import { fetchPointData } from "../../apis/backend";
import { variableNames } from "../../types/VariableNames";
import { Place, getPlaces, savePlace } from "../../types/Place";
import * as geonorgeStedsnavn from "../../apis/geonorge/stedsnavn";
import { DEFAULT_PLACES } from "../frontpage/FrontPageSuggestedPlace";
import { useNavigate } from "react-router-dom";
interface OverviewProps {
	position: LatLng;
	setPosition: (newPos: LatLng) => unknown;
}

const Overview = ({ position, setPosition }: OverviewProps): JSX.Element => {
	// Destructure the pos, for equality check in dependency arrays
	const navigate = useNavigate();
	const { lat, lng } = position;

	// The relative day the user is looking at
	const [selectedDay, setSelectedDay] = useState<AvailableDays>("today");

	// The data for the position
	const [pointData, setPointData] = useState<PointData[] | null>(null);

	// Name of this place
	const [placeName, setPlaceName] = useState<string | undefined>(undefined);
	const [place, setPlace] = useState<string | undefined>(undefined);
	const existingPlaces = getPlaces();

	/** Deletes the current data and updates the position, if the new position is different from the current */
	const setNewPosition = (newPos: LatLng) => {
		// Do nothing if the position hasn't changed
		if (newPos.lat === position.lat && newPos.lng === position.lng) {
			return;
		}

		// Delete the current data
		setPointData(null);

		// Tell the parent the position has changed
		setPosition(newPos);
	};

	/** Handles results from the place name search */
	const handleNavnResult = (data: Skrivemate) => {
		// Convert the result to a place object
		const place = geonorgeStedsnavn.skrivemåteToPlace(data);
		// Save its position to state
		setNewPosition(place.position);
		//Navigate to the chosen place
		navigate(`/oversikt/${place.position.lat}/${place.position.lng}`, { replace: true });
		// Save the result
		setPlace(place.name);
		savePlace(place);
	};

	/** Handles the positive result from getting the geolocation */
	const handleGeolocation = (geolocation: GeolocationPosition) => {
		const pos = {
			lat: geolocation.coords.latitude,
			lng: geolocation.coords.longitude
		};

		setNewPosition(pos);
		//Navigate to the current position
		navigate(`/oversikt/${pos.lat}/${pos.lng}`, { replace: true });
	};

	/** Handles errors when getting geolocation */
	const handleGeolocationError = (err: GeolocationPositionError): void => {
		console.error(err);
		if (err.message.toLowerCase() === "user denied geolocation") {
			// If permission to get location is disabled
			alert("Fikk ikke tillatelse til å hente din posisjon");
		} else {
			alert("Kunne ikke hente din posisjon");
		}
	};

	const newsArticlePreviewArr = [
		{
			title: "Hvorfor kan man få forskjeller mellom Havvarsel og virkeligheten?",
			text: "Lune og grunne badebukter er mindre enn en rute i modellens rutenett.",
			link: "/om/havvarselforskjell",
			image: "/info/havvarselforskjell.jpg"
		},
		{
			title: "Søtt & salt",
			text: "Sjøvann kan være både ferskt og salt fra sted til sted.",
			link: "/om/soettogsalt",
			image: "/info/sottogsalt.jpg"
		},
		{
			title: "Hvorfor varierer sjøtemperaturen?",
			text: "Både vær og dypvann påvirker badetemperaturen.",
			link: "/om/sjoetemperatur",
			image: "/info/sjotemperatur.jpg"
		}
	];

	// Update the data when the position changes
	useEffect(() => {
		// Keep track of whether or not the component is mounted
		let isMounted = true;

		void (async () => {
			// TODO Handle errors

			// Define the timespan to get data for
			const after = dateFns.startOfDay(new Date());
			const before = dateFns.add(after, { hours: 72 });

			// Get the data for the point
			const pointData = await fetchPointData({
				after,
				before,
				lat,
				lon: lng,
				variable: [...variableNames] // XXX I do not know why cloning is necessary. TS complains about some readonly crap if I don't
			}).catch(() => {
				return null;
			});
			// Do not update state after component has unmounted
			if (!isMounted) {
				return;
			}

			setPointData(pointData);
		})();

		return () => {
			// Mark it as unmounted
			isMounted = false;
		};
	}, [lat, lng]);

	// Define the available days. For today, it's the current hour. For the others, it's 12:00
	const today = dateFns.startOfHour(new Date());
	const dayMap = new Map<AvailableDays, Date>([
		["today", today],
		["tomorrow", dateFns.set(dateFns.add(today, { days: 1 }), { hours: 12 })],
		["overmorrow", dateFns.set(dateFns.add(today, { days: 2 }), { hours: 12 })]
	]);

	// Find the timestamp to use for current data
	const dataHour = dayMap.get(selectedDay) ?? new Date(); // XXX The default is there just to get the type right. It will never happen

	// Find the data for that timestamp
	const currentPointData = pointData?.find(({ timestamp }) => dateFns.isSameHour(timestamp, dataHour));

	// Filter out the data for the desired day
	// Define the timespan from which to extract data
	const day = dayMap.get(selectedDay) ?? new Date(); // XXX The default is there just to get the type right. It will never happen
	const after = dateFns.add(dateFns.startOfDay(day), { hours: 1, seconds: -1 }); // `isAfter` is exclusive, so need a timesamp slightly earlier than what I actually want
	const before = dateFns.add(after, { days: 1 });
	const dayData =
		pointData?.filter(
			({ timestamp }) => dateFns.isAfter(timestamp, after) && dateFns.isBefore(timestamp, before)
		) ?? [];

	// Get the name of this location
	const { lat: nord, lng: ost } = pointData?.[0].position ?? {};
	useEffect(() => {
		// No point getting the place name of an undefined location
		if (nord === undefined || ost === undefined) {
			return;
		}

		let isMounted = true;

		void (async () => {
			// Search GeoNorge for this location
			const result = await geonorgeStedsnavn.punkt({
				nord,
				ost,
				koordsys: 4326
			});

			// Bail out if the component has unmounted
			if (!isMounted) {
				return;
			}

			setPlaceName(result.navn[0]?.stedsnavn[0]?.skrivemåte);
			const name = findPlaceOfCurrentUrl(existingPlaces, lat, lng);
			if (!name) {
				setPlace(result.navn[0]?.stedsnavn[0]?.skrivemåte);
			}
		})();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nord, ost]);

	useEffect(() => {
		const name = findPlaceOfCurrentUrl(existingPlaces, lat, lng);
		if (name) {
			setPlace(name);
		}
	}, [existingPlaces, lat, lng]);

	const findPlaceOfCurrentUrl = (places: Place[], lat: number, lng: number): string | undefined => {
		const combinedPlaces = [...places, ...DEFAULT_PLACES];
		const res = combinedPlaces.find(place => place.position.lat === lat && place.position.lng === lng);
		return res ? res.name : undefined;
	};

	return (
		<React.Fragment>
			<div className="background-primary-one">
				<div className="container">
					<div className="row">
						<div className="col-12 p-5">
							<PlaceSelector
								onNavnResult={handleNavnResult}
								onGeolocation={handleGeolocation}
								onGeolocationError={handleGeolocationError}
								defaultValue={placeName}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<SelectDay selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
						</div>
					</div>
				</div>
			</div>
			<div className="background-secondary-one overview-illustrations">
				<div className="container">
					<div className="row">
						<div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
							<div className="row">
								<div className="col-12 text-center">
									<h1>{place}</h1>
									{currentPointData?.temperature !== undefined ? (
										<CurrentTemperature currentTemperature={currentPointData.temperature} />
									) : null}
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<HourlyTableData data={dayData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="jumbotron-fluid background-primary-one pt-4">
				<SuggestedPlaceMap position={position} />
			</div>
			<div className="background-primary-one pt-5 pb-5">
				<div className="container font-color-primary-three">
					{/* <FunFacts /> */}
					{newsArticlePreviewArr.map((news, i) => {
						// Someties, orderLeft should be set to true (depending on if a news article preview should come before fun facts)
						const orderLeft = i % 2 === 0 ? true : false;
						return <NewsArticlePreview key={i} news={news} orderLeft={orderLeft} />;
					})}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Overview;
export { Overview };
