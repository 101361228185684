const frontpage = {
	header: "Havvarsel",
	oceanTemperatures: "Badetemperaturer",
	seeTheOceanTemperatureWhereYouAre: "Se badetemperaturen der du er",
	mapSolution: "Kartløsning",
	seeOceanDataFromEntireNorway: "Se havdata fra hele Norge"
};

export default frontpage;
export { frontpage };
