import React from "react";
import { useNavigate } from "react-router-dom";
import FrontPageMap from "../frontpage/FrontPageMap";
import FunFacts from "../misc/FunFacts";
import FrontPageSuggestedPlace from "../frontpage/FrontPageSuggestedPlace";
import PlaceSelector from "../misc/PlaceSelector";
import { Skrivemate } from "../../apis/geonorge/stedsnavn/types/Skrivemate";
import { savePlace } from "../../types/Place";
import { skrivemåteToPlace } from "../../apis/geonorge/stedsnavn";
import NewsArticlePreview from "../misc/NewsArticlePreview";

const Frontpage = (): JSX.Element => {
	const navigate = useNavigate();

	/** Handles results from the place name search */
	const handleNavnResult = (data: Skrivemate) => {
		// Convert the result to a place object
		const place = skrivemåteToPlace(data);

		// Save the result
		savePlace(place);

		// Navigate to this place
		const path = `/oversikt/${place.position.lat}/${place.position.lng}`;
		navigate(path);
	};

	/** Handles the positive result from getting the geolocation */
	const handleGeolocation = (geolocation: GeolocationPosition) => {
		// Extract the position
		const { latitude: lat, longitude: lng } = geolocation.coords;

		const path = `/oversikt/${lat}/${lng}`;
		navigate(path);
	};

	/** Handles errors when getting geolocation */
	const handleGeolocationError = (err: GeolocationPositionError): void => {
		/* TODO */
		if (err.message.toLowerCase() === "user denied geolocation") {
			// If permission to get location is disabled
			alert("Fikk ikke tillatelse til å hente din posisjon");
		} else {
			alert("Kunne ikke hente din posisjon");
		}
	};

	const newsArticlePreviewArr = [
		{
			title: "Hvorfor varierer sjøtemperaturen?",
			text: "Både vær og dypvann påvirker badetemperaturen.",
			link: "/om/sjoetemperatur",
			image: "/info/sjotemperatur.jpg"
		},
		{
			title: "Hvor salt er egentlig havet?",
			text: "Salt i havet gjør at du flyter bedre enn i ferskvann, men bare litt.",
			link: "/om/saltholdighet",
			image: "/info/saltholdighet.jpg"
		},
		{
			title: "Lær mer om havet",
			text: "Norge har en lang kyst og lang tradisjon for å bruke og innhente kunnskap omhavet. På denne siden kan du finne linker til ulike saker om havet og om	havforskning.",
			link: "/om/havet",
			image: "/info/havet.jpg"
		},
		{
			title: "Lær mer om vind",
			text: "Vind er luft i bevegelse",
			link: "/om/vind",
			image: "/info/vind.jpg"
		},
		{
			title: "Lær mer om strøm",
			text: "Både vind, tidevann, elveavrenning og strømsystemene ute i havet påvirker strømmene i overflaten langs kysten vår.",
			link: "/om/strom",
			image: "/info/strom.jpg"
		}
	];

	return (
		<React.Fragment>
			<div className="background-primary-one frontpage-illustration">
				<div className="container">
					<div className="row">
						<div className="offset-md-3 col-md-6 col-12">
							<div className="row">
								<div className="col-12 text-center mt-5">
									<h1 className="font-color-primary-three">Ohoi</h1>
									<h1 className="font-color-primary-three">Hvor vil du til sjøs?</h1>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<PlaceSelector
										onNavnResult={handleNavnResult}
										onGeolocation={handleGeolocation}
										onGeolocationError={handleGeolocationError}
									/>
									<FrontPageSuggestedPlace />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="jumbotron">
				<FrontPageMap />
			</div>
			<div className="background-primary-one pt-2 pb-2">
				<div className="container font-color-primary-three">
					<NewsArticlePreview key={0} news={newsArticlePreviewArr[0]} orderLeft={true} />
				</div>
			</div>
			<div className="background-primary-one pt-2 pb-2">
				<FunFacts />
			</div>
			<div className="background-primary-one pt-2 pb-2">
				<div className="container font-color-primary-three">
					{newsArticlePreviewArr.map((news, i) => {
						// Someties, orderLeft should be set to true (depending on if a news article preview should come before fun facts)
						const orderLeft = i % 2 !== 0 ? true : false;
						if (i === 0) {
							return null;
						}
						return <NewsArticlePreview key={i} news={news} orderLeft={orderLeft} />;
					})}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Frontpage;
export { Frontpage as FrontPageNew };
