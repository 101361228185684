import settings from "../../../settings";

const Havvarsel = (): JSX.Element => {
	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/havet.jpg"} alt="" />
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Lær mer om havet</h1>
						<h5>
							Norge har en lang kyst og lang tradisjon for å bruke og innhente kunnskap om havet. På denne
							siden finner du lenker til ulike saker om havet og om havforskning, og andre nyttige
							tjenester.
						</h5>
						<hr />
						<h2>Temasider</h2>
						<a href="https://www.hi.no/hi/temasider/hav-og-kyst/hav-kyst-og-fjord/kyst-og-fjord">
							<p className="bold-text white-color url-underline">Kyst og Fjord</p>
						</a>
						<p>
							Kystlinjen vår strekker seg over ca. 23 breddegrader fra Lindesnes til Svalbard, og
							forholdene langs kysten svært ulike fra sør til nord. Strømforhold og geografi spiller en
							stor rolle for sjøens egenskaper der du er.
						</p>
						<a href="https://www.hi.no/hi/temasider/hav-og-kyst/modeller">
							<p className="bold-text white-color url-underline">Modeller</p>
						</a>
						<p>
							Modeller gir en forenklet beskrivelse av virkeligheten, og brukes til å skaffe utfyllende
							informasjon i kombinasjon med observasjoner. Modeller kan dessuten brukes til å beskrive noe
							som er vanskelig eller umulig å måle direkte.
						</p>
						<a href="https://www.hi.no/hi/temasider/hav-og-kyst/klimaet-i-havet">
							<p className="bold-text white-color url-underline">Klimaet i havet</p>
						</a>
						<p>
							Klimaendringene påvirker havet og alt som lever i det. Hva er det som endrer seg, og hvordan
							overvåker vi dette?
						</p>
						<a href="https://www.met.no/vaer-og-klima">
							<p className="bold-text white-color url-underline">
								Meteorologisk institutt om vær og klima
							</p>
						</a>
						<p>
							Her finner du lenker til værvarsel, farlig vær, spesialvarsel, klima, værhistorikk,
							observasjoner, podcast og klimasvar.
						</p>
						<a href="https://www.hi.no/hi/temasider">
							<p className="bold-text white-color url-underline">Havforskningsinstituttet om havet</p>
						</a>
						<p>Her finner du flere artikler om livet og fysikken i havet, og om forskning og overvåking.</p>
						<h2>Tjenester</h2>
						<a href="https://stromkatalogen.hi.no ">
							<p className="bold-text white-color url-underline">stromkatalogen.hi.no</p>
						</a>
						<p>
							Se kart over strømforhold langs kysten, med statistisk informasjon om strøm og spredning med
							strømmen.
						</p>
						<a href="https://kartverket.no/sehavniva">
							<p className="bold-text white-color url-underline">kartverket.no/sehavniva</p>
						</a>
						<p>
							Finn informasjon om observert og varslet vannstand, tidevann, landheving og framtidig
							havnivå ved å søke på et sted.
						</p>
						<a href="https://algestatus.hi.no">
							<p className="bold-text white-color url-underline">algestatus.hi.no</p>
						</a>
						<p>
							Havforskningsinstituttet har som mål å samle og gjøre tilgjengelig informasjon om
							planteplankton fra instituttets overvåkningsaktivitet og andre overvåkningsprogrammer. Se
							blant annet varsel om gift i blåskjell.
						</p>
						<a href="https://dugnadforhavet.no">
							<p className="bold-text white-color url-underline">dugnadforhavet.no</p>
						</a>
						<p>
							Vi trenger din hjelp for å overvåke hvordan det går med livet i havet. Klimaendringene gjør
							at det er mye som endrer seg, blant annet dukker nye arter opp langs vår lange kyst. Vi
							trenger din hjelp til å følge med på hva som skjer. Her kan du melde inn dine funn og se hva
							andre har funnet.
						</p>
						<a href="https://yr.no">
							<p className="bold-text white-color url-underline">Yr.no</p>
						</a>
						<p>
							Norges mest kjente værvarslingstjeneste. Utviklet og drevet av Meteorologisk institutt og
							NRK.
						</p>
					</div>
				</div>
			</article>
		</div>
	);
};

export default Havvarsel;
export { Havvarsel };
