import Control from "ol/control/Control";
import Interaction from "ol/interaction/Interaction";
import { useContext, useEffect } from "react";
import MapContext from "./MapContext";

const MapHack = (): null => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		// Need the map to be able to do anything
		if (map === null) {
			return;
		}

		const controls = [] as Control[];
		map.getControls().forEach(control => controls.push(control));
		const interactions = [] as Interaction[];
		map.getInteractions().forEach(interaction => interactions.push(interaction));

		controls.forEach(control => map.removeControl(control));
		interactions.forEach(interaction => map.removeInteraction(interaction));
	}, [map]);

	return null;
};

export default MapHack;
export { MapHack };
