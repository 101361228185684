import VectorLayer from "ol/layer/Vector";
import { useContext, useEffect, useCallback, useState } from "react";
import MapContext from "../MapContext";
import VectorSource from "ol/source/Vector";
import { WindAndCurrent } from "../../../apis/backend/types/GetWindAndCurrent";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import { transform } from "ol/proj";
import getArrowStyle from "./ArrowStyle";
import WKT from "ol/format/WKT";
import { Style, Fill } from "ol/style";
import { getLayerForVariable } from "../../../apis/backend/variableMaps";
import { currentColorScale, windColorScale } from "../../misc/ColorScales";
import Cluster from "ol/source/Cluster";
import { Current, Wind } from "../../../apis/backend/types/Wind";

interface WindAndCurrentLayerProps {
	/** WMS layer to use */
	layerName: string;
	/** Wind and current data object from api call*/
	windAndCurrentData: WindAndCurrent | null;
	/** Stringified Polygon for wind and current layer */
	windAndCurrentPolygon: string | null;
	/** What layers are available */
	availableLayers: string[];
	/** Where in the layer stack this layer should be placed */
	zIndex: number;
}

const WindAndCurrentLayer = ({
	layerName,
	windAndCurrentData,
	windAndCurrentPolygon,
	availableLayers,
	zIndex
}: WindAndCurrentLayerProps): null => {
	// Extract the map from the context
	const { map } = useContext(MapContext);
	const [polygonLayer, setPolygonLayer] = useState<VectorLayer<VectorSource> | null>(null);

	// Used to track which color scale to use
	const [colorScale, setColorScale] = useState<Map<number, string>>(windColorScale);

	if (windAndCurrentPolygon && !polygonLayer) {
		const polygonRaw = windAndCurrentPolygon;

		const format = new WKT();
		const feature = format.readFeature(polygonRaw, {
			dataProjection: "EPSG:4326",
			featureProjection: "EPSG:3857"
		});

		const layer = new VectorLayer({
			source: new VectorSource({ features: [feature] }),
			style: new Style({
				fill: new Fill({ color: "white" })
			})
		});

		layer.setOpacity(0.2);
		layer.setZIndex(zIndex);
		setPolygonLayer(layer);
	}

	// Prevent new function created every time, by using useCallback
	const getWindAndCurrentVectorSource = useCallback(() => {
		const allFeatures: Array<Feature> = [];
		windAndCurrentData?.data.map(c => {
			const coord = [c.coords.lon, c.coords.lat];
			const point = new Point(transform(coord, "EPSG:4326", "EPSG:3857"));
			const feature = new Feature(point);
			// Adds wind and current data to the point, this is used to style the arrows later.
			feature.setProperties(c);
			allFeatures.push(feature);
			return allFeatures;
		});

		const source = new VectorSource({ features: allFeatures });
		return source;
	}, [windAndCurrentData]);

	// Create cluster
	// Prevent new function created every time, by using useCallback
	const createCluster = useCallback(
		(distance: number) => {
			if (distance >= 5 && distance <= 200) {
				const newCluster = new Cluster({
					distance: distance,
					source: getWindAndCurrentVectorSource()
				});
				return newCluster;
			} else {
				return undefined;
			}
		},
		[getWindAndCurrentVectorSource]
	);

	// Create clusterlayer for either wind or current
	// Prevent new function created every time, by using useCallback
	const createClusterLayer = useCallback(
		(source: Cluster, type: string) => {
			if (type !== "wind" && type !== "current") return undefined;
			if (source instanceof Cluster) {
				const layer = new VectorLayer({
					source: source,
					style: feature => {
						const clusterFeature = feature.get("features") as Array<Feature>;
						const windOrCurrent = { ...clusterFeature[0].get(`${type}`) } as Wind | Current;
						// Decompose winds/currents into x, y components based on direction and strength and cumulate these into xCoords and yCoords.
						// TODO: Make use of u_eastward, v_northward (current), wind_eastwards, wind_northwards (wind) as vectors instead of creating new ones.
						const weightedSum = clusterFeature.reduce(
							(acc: { xCoords: number; yCoords: number; strengthSum: number }, e: Feature) => {
								const { direction, strength } = e.get(`${type}`) as Wind | Current;
								acc.xCoords += Math.cos(direction) * strength;
								acc.yCoords += Math.sin(direction) * strength;
								acc.strengthSum += strength;
								return acc;
							},
							{ xCoords: 0, yCoords: 0, strengthSum: 0 }
						);
						const count = clusterFeature.length;
						// Check if we have data points to aggregate
						if (count !== 0) {
							// Calculate avg strength for aggregated arrows
							windOrCurrent.strength = weightedSum.strengthSum / count;
							// Calculate new direction for aggregated arrows
							// Note: As wind and current are relative opposites in regards to rotation, check for which one is in question
							if (type === "current") {
								windOrCurrent.direction =
									Math.atan2(weightedSum.yCoords, weightedSum.xCoords) + 2 * Math.PI; // If in current, add 2*PI
							} else {
								windOrCurrent.direction =
									Math.atan2(weightedSum.yCoords, weightedSum.xCoords) - 2 * Math.PI; // If in wind, subtract 2*PI
							}
						} else {
							// Handle the case where there are no non-zero elements
							windOrCurrent.direction = 0;
							windOrCurrent.strength = 0;
						}
						return getArrowStyle(windOrCurrent, colorScale);
					}
				});
				return layer;
			} else {
				return undefined;
			}
		},
		[colorScale]
	);

	useEffect(() => {
		if (map && polygonLayer) {
			map.addLayer(polygonLayer);
			return () => {
				map.removeLayer(polygonLayer);
			};
		}
	}, [polygonLayer, map]);

	// Update arrows when new data fetched
	useEffect(() => {
		if (windAndCurrentData !== null && map) {
			const windAndCurrentVectorSource = getWindAndCurrentVectorSource();
			if (windAndCurrentVectorSource && layerName === getLayerForVariable("wind", availableLayers)) {
				setColorScale(windColorScale);

				const cluster = createCluster(40);
				if (cluster === undefined) {
					// Will be typeof Cluster, but used to avoid undefined
					return;
				}
				const clusterLayer = createClusterLayer(cluster, "wind");
				if (clusterLayer === undefined) {
					// Will be typeof VectorLayer, but used to avoid undefined
					return;
				}
				map.addLayer(clusterLayer);
				return () => {
					map.removeLayer(clusterLayer);
				};
			}
			if (windAndCurrentVectorSource && layerName === getLayerForVariable("current", availableLayers)) {
				setColorScale(currentColorScale);

				const cluster = createCluster(30);
				if (cluster === undefined) {
					// Will be typeof Cluster, but used to avoid undefined
					return;
				}
				const clusterLayer = createClusterLayer(cluster, "current");
				if (clusterLayer === undefined) {
					// Will be typeof VectorLayer, but used to avoid undefined
					return;
				}
				map.addLayer(clusterLayer);
				return () => {
					map.removeLayer(clusterLayer);
				};
			}
		}
	}, [
		windAndCurrentData,
		layerName,
		zIndex,
		map,
		getWindAndCurrentVectorSource,
		availableLayers,
		createClusterLayer,
		colorScale,
		createCluster
	]);

	return null;
};

export default WindAndCurrentLayer;
export { WindAndCurrentLayer };
