import { useEffect, useState } from "react";
import { LatLng } from "../../types/LatLng";
import * as backend from "../../apis/backend";
import * as dateFns from "date-fns";
import styles from "./SuggestedPlace.module.scss";
import { PointData } from "../../types/PointData";
import { variableNames } from "../../types/VariableNames";
import { generatePath, Link } from "react-router-dom";

interface SuggestedPlaceProps {
	name: string;
	placeType: string;
	kommune: string | null;
	position: LatLng;
}

const SuggestedPlace = ({ name, placeType, kommune, position }: SuggestedPlaceProps): JSX.Element => {
	const [pointData, setPointData] = useState<PointData[] | null>(null);
	useEffect(() => {
		// Keep track of the component's mounted status
		let isMounted = true;

		void (async () => {
			const after = dateFns.startOfHour(new Date());
			const before = dateFns.add(after, { hours: 1 });
			const pointData = await backend
				.fetchPointData({
					after,
					before,
					variable: [...variableNames],
					lat: position.lat,
					lon: position.lng
				})
				.catch(() => {
					return null;
				});

			// Avoid updating state if the component is unmounted
			if (!isMounted) {
				return;
			}

			setPointData(pointData);
		})();

		return () => {
			// Mark the component as unmounted
			isMounted = false;
		};
	}, [position]);

	// Get the data for the previous hour
	const now = new Date();
	const data = pointData?.find(data => dateFns.isSameHour(data.timestamp, now));

	const temperature = data?.temperature !== undefined ? Math.round(data.temperature) : "X";
	const salinity = data?.salinity?.toFixed(0) ?? "X";

	return (
		<Link
			to={generatePath("/oversikt/:lat/:lng", {
				lat: String(position.lat),
				lng: String(position.lng)
			})}
			className="link-unstyled"
		>
			<div className={`row g-0 align-items-center ${styles["shape-and-background-color"]}`}>
				<div className={`col-4 text-center ${styles["small-left-padding"]}`}>
					<div className="bold-text">{name}</div>
					<div className={`${styles["font-size-placename"]}`}>
						{placeType}, {kommune}
					</div>
				</div>
				<div className="col-4 text-center">
					<div className={`${styles["variable-title"]}`}>Sjøtemp</div>
					<span className={`${styles["variable-value"]}`}>{temperature} °C</span>
				</div>
				<div className="col-4 text-center">
					<div className={`${styles["variable-title"]}`}>Salt</div>
					<div className={`${styles["variable-value"]}`}>{salinity} ‰</div>
				</div>
			</div>
		</Link>
	);
};

export default SuggestedPlace;
export { SuggestedPlace };
