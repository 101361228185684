import settings from "../../../settings";

const Prosjektet = (): JSX.Element => {
	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/prosjektet.jpg"} alt="" />
				</div>
				<div className="row mt-3">
					<div>Om oss</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Om prosjektet</h1>
						<h5>Prosjektet Havvarsel er finansiert av Norges forskningsråd.</h5>
						<hr />
						<p>
							Utviklingen av prosjektet foregår i forskningsprosjektet HAVVARSEL som er finansiert av
							IKTPLUSS programmet i{" "}
							<a href="https://prosjektbanken.forskningsradet.no" className="white-color url-underline">
								Norges forskningsråd.
							</a>
						</p>
						<p>
							Prosjektpartnerne Havforskningsinstituttet, Meteorologisk institutt og SINTEF Digital
							utvikler sammen forbedrede simuleringsverktøy og en digital infrastruktur for å lage lokale
							havvarsel. Utviklingen skjer i tett dialog med interessenter fra industri, offentlige
							tjenester og media for å sørge for at tjenesten inneholder mest mulig brukervennlig
							informasjon. Vi utnytter synergieffekter med andre pågående prosjekter og aktiviteter hos
							hver av samarbeidspartnerne. Havforskningsinstituttet er prosjektkoordinator. Selve driften
							av tjenesten gjøres av Havforskningsinstituttet og Meteorologisk institutt.
						</p>
						<h2 className="bold-text">Havforskningsinstituttet</h2>
						<p>
							Havforskningsinstituttet (HI) overvåker og forsker på havet og havets levende ressurser for
							å gi råd til norske myndigheter. HI har også et samfunnsansvar for å legge til rette
							informasjon om havets miljø, de høstbare bestandene (som fisk, hval, sel, skjell og krabber)
							og de marine økosystemene generelt til allmennheten og næringer tilknyttet havet. Derfor har
							HI også det nasjonale ansvar for datainnsamling av tilstanden til havet, som sjøtemperatur,
							saltholdighet og havstrømmer.
						</p>
						<h2 className="bold-text">Meteorologisk institutt</h2>
						<p>
							Meteorologisk institutt (MET) varsler været, overvåker klimaet og driver forsking. Siden
							instituttet ble etablert i 1866, har MET sine forskere stått sentralt i utviklingen av
							meteorologi og oseanografi. MET er i dag et ledende internasjonalt kompetansemiljø og har
							det nasjonale ansvaret for produksjon av havvarsler ved hjelp av generelle
							sirkulasjonsmodeller, i tillegg til bølge-, sjøis- og værvarsler.
						</p>
						<h2 className="bold-text">SINTEF Digital</h2>
						<p>
							SINTEF Digital er representert i prosjektet gjennom avdelingen Mathematics & Cybernetics. De
							sitter på ekspertise og erfaringer innen beregningskrevende geovitenskap, high-performance
							computing, stordata, og kunstig intelligens, og innehar en sterk akademisk stilling i det
							internasjonale forskningsmiljøet.
						</p>
						<h2 className="bold-text">Teamet bak denne tjenesten</h2>
						<h5>Fra Havforskningsinstituttet</h5>
						<p>
							Jan Erik Stiansen (prosjektleder og kontaktperson Havforskningsinstituttet), Jon Albretsen,
							Lars Asplin, Tony Nguyen Huynh, Øyvind Jakobsson, Karsten Arne Kvalsund, Simen de Lange,
							Sjur Ringheim Lid, Endre Moen, Mari Skuggedal Myksvoll, Jan Even Øie Nilsen, Jenny Elisabet
							Ullgren, Morten Marthinussen, Manish Ruhella, Anitra Kruse, Stian Botnevik.
						</p>
						<h5>Fra Meteorologisk institutt</h5>
						<p>
							Sætra, Martin Lilleeng (lokal prosjektleder og kontaktperson Meteorologisk institutt), Jo
							Asplin, Arild Burud, Kai Christensen, Ann Kristin Sperrevik.
						</p>
						<h5>Fra SINTEF Digital </h5>
						<p>
							Håvard Heitlo Holm (lokal prosjektleder og kontaktperson SINTEF Digital), Odd Andersen,
							Florian Beiser.
						</p>
						<h5>Fra KnowIT</h5>
						<p>Maria Nordtveit (UX-design), Marius Haugen.</p>
						<a
							href="https://metno.github.io/havvarsel-website"
							className="bold-text text-center white-color url-underline our-project-pages-url"
						>
							Her er prosjektsidene våre
						</a>
					</div>
				</div>
			</article>
		</div>
	);
};

export default Prosjektet;
export { Prosjektet };
