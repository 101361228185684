import * as dateFns from "date-fns";
import settings from "../../../settings";

const Vind = (): JSX.Element => {
	const publicationDate = new Date("2022-07-01T10:00:00+0200");

	return (
		<div className="background-primary-one font-color-primary-three">
			<article className="container info-page">
				<div className="row">
					<img className="img-fluid col-12" src={settings.PUBLIC_URL + "/info/vind.jpg"} alt="" />
				</div>
				<div className="row mt-3">
					<div>Havprat</div>
					<div>
						<img src="/wave_short.svg" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h1 className="bold-text">Hva kommer med blesten?</h1>
						<h5>Vind er luft i bevegelse</h5>
						<div className="text-muted">
							Publisert{" "}
							<time dateTime={publicationDate.toISOString()}>
								{dateFns.format(publicationDate, "dd.MM.yyyy")}
							</time>
							<div>Tekst: J. Even Ø. Nilsen</div>
						</div>
					</div>
					<hr />
					<p>
						Årsaken til vind er forskjeller i lufttrykk, som igjen skyldes temperaturforskjeller over kloden
						eller lokale effekter av nedkjøling eller oppvarming av luftmasser. Vind blåser i utgangspunktet
						fra høytrykk mot lavtrykk, men jordrotasjonen gjør at vinden går med høyt trykk til høyre og
						lavt trykk til venstre (på den nordlige halvkule). Jo større trykkforskjeller, jo sterkere vind.
					</p>
					<p>
						Vindretningen ved havoverflaten skyldes et komplisert samspill mellom flere faktorer. Svake
						vinder blåser ofte parallelt med kystlinjer, fjorder og dalsider, mens større og sterkere
						vindsystemer kan presse luft på tvers av dette.
					</p>
					<p>
						Solgangsvind (‘solgangsbris’) er et godt eksempel på vind som varierer med tiden. Siden sjø kan
						ta opp i seg mer varme enn land, vil temperaturen på land variere sterkest i takt med solen. På
						ettermiddagen blir det varmest over land, og luften der varmes opp, stiger, og erstattes av luft
						fra sjøen – pålandsvind. Om natten skjer som regel det motsatte og vi får en svak fralandsvind.
						Jordens rotasjon gjør at vinden ikke nødvendigvis går rett på eller fra land, men bøyes av mot
						høyre og blir til vind langs land.
					</p>
					<p>
						Sterkere vinder kommer med de store lavtrykksystemene når disse møter landet vårt. De kan
						variere fra helt ufarlige vindsystemer til orkaner. Forskjellene er store mellom fjord og kyst.
						På utsatte steder som Lista, Kråkenes, Nordøyan og Vardø opptrer vindstyrke liten kuling eller
						mer nesten 10 ganger så ofte som i fjordene. Liten storm eller mer er svært sjeldent i skjermede
						områder.
					</p>
					<p>
						Vind er og har alltid vært avgjørende for all aktivitet på og nær sjøen. Både som drivkraft og
						energikilde, eller som en ødeleggende kraft. Alle som skal på eller i sjøen bør være spesielt
						oppmerksom på vinden og hvordan den blir varslet.
					</p>
					<p>Kilder:</p>
					<p>
						Dannevig, Petter (Meteorologisk institutt); Harstveit, Knut: vind i Store norske leksikon på
						snl.no. Hentet 21. juni 2022 fra{" "}
						<a href="https://snl.no/vind" target="_blank">
							<span className="white-color url-underline">https://snl.no/vind</span>
						</a>
					</p>
					<p>
						Dannevig, Petter; Harstveit, Knut: klima i Norge i Store norske leksikon på snl.no. Hentet 21.
						juni 2022 fra{" "}
						<a href="https://snl.no/klima_i_Norge" target="_blank">
							<span className="white-color url-underline">https://snl.no/klima_i_Norge</span>
						</a>
					</p>
				</div>
			</article>
		</div>
	);
};

export default Vind;
export { Vind };
