import { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import { PointData } from "../../../types/PointData";
import { VariableNames } from "../../../types/VariableNames";
import { variableOptions } from "./VariableOptions";

// TODO: Dynmaically set language somehow
Highcharts.setOptions({
	lang: {
		loading: "Loading...",
		months: [
			"Januar",
			"Februar",
			"Mars",
			"April",
			"Mai",
			"Juni",
			"Juli",
			"August",
			"September",
			"Oktober",
			"November",
			"Desember"
		],
		weekdays: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
		shortMonths: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"]
	}
});

interface ChartProps {
	variable: VariableNames;
	dataForGraph: PointData[];
}

type ChartSeriesOptions = Partial<Highcharts.SeriesOptionsType> & Record<string, unknown>;
const Chart = ({ variable, dataForGraph }: ChartProps): JSX.Element => {
	// Reference to the element to render the chart into
	const chartElemRef = useRef<HTMLDivElement>(null);

	const dateNow = new Date();
	// Filter all data from current time and after
	// Turn the data into x/y format
	const data = dataForGraph
		.filter(({ timestamp, [variable]: value }) => {
			if (dateNow.getTime() < timestamp.getTime()) {
				return {
					x: timestamp.getTime(),
					y: value
				};
			}
		})
		.map(({ timestamp, [variable]: value }) => ({
			x: timestamp.getTime(),
			// Reduce temp and salinity to specified number of decimals in the chart
			...(variable === "temperature" || variable === "salinity" ? { y: Number(value.toFixed(2)) } : { y: value })
		}));
	// Figure out the style of the graph
	const variableOption = variableOptions[variable];
	const series = [
		{
			setVisible: false,
			name: variableOption.title,
			data: data,
			type: "spline",
			marker: {
				enabled: false,
				states: {
					hover: {
						enabled: true
					}
				}
			},
			tooltip: {
				pointFormat: variableOption.tooltipPointFormat
			},
			zIndex: 1,
			color: variableOption.lineColor
		}
	];

	const chartOptions = {
		chart: {
			scrollablePlotArea: {
				// Enable scrolling on mobile
				minWidth: 500,
				scrollPositionX: 0
			},
			backgroundColor: "#E3F3FB",
			marginLeft: 32,
			height: "100%"
		},
		accessibility: {
			enabled: false
		},
		title: {
			text: ""
			// text: variableOption.title,
			// align: "left",
			// x: 0, // Aligns the title positioning
			// style: {
			// 	whiteSpace: "nowrap",
			// 	textOverflow: "ellipsis"
			// }
		},
		time: {
			useUTC: false // If true, it displays GMT
		},
		credits: {
			text: ""
		},
		tooltip: {
			shared: true,
			useHTML: true,
			headerFormat: "",
			enabled: true // If true, hover / (hold on mobile) will show value
		},
		xAxis: [
			{
				// Bottom X axis
				tickInterval: 3600 * 1000 * 2,
				minorTickInterval: 36e5,
				tickLength: 0,
				gridLineWidth: 1,
				gridLineColor: "rgba(128, 128, 128, 0.1)",
				startOnTick: false,
				endOnTick: false,
				minPadding: 0,
				maxPadding: 0,
				showLastLabel: true,
				labels: {
					x: 10,
					format: "{value:%H}" // Bottom X axis hours
				},
				crosshair: true
				// width: 1000 // Sets width of the X Axis
			},
			{
				// Top X axis
				linkedTo: 0,
				tickInterval: 24 * 3600 * 1000,
				labels: {
					format: '{value:<span style="font-size: 12px; font-weight: bold">%a</span> %b %e}' // TODO: Fix this so we get norwegian / english date at the top of the X axis
				},
				opposite: true,
				tickLength: 20,
				gridLineWidth: 1,
				gridLineColor: "#000000",
				lineWidth: 2
			}
		],
		yAxis: [
			{
				title: {
					align: "high",
					text: variableOption.YLabelText,
					style: {
						"text-anchor": "start"
					},
					rotation: 0,
					y: -5
					// x: -25
				},
				labels: {
					format: variableOption.YAxisLabel,
					style: {
						fontSize: "10px"
					},
					x: -4
				},
				maxPadding: 0.3,
				// minRange: 6, // Sets range on the Y axis
				tickInterval: 1,
				gridLineColor: "rgba(128, 128, 128, 0.1)"
			}
		],
		legend: {
			enabled: false
		},
		plotOptions: {
			series: {
				pointPlacement: "between"
			}
		},
		series: series as Array<ChartSeriesOptions> as Array<Highcharts.SeriesOptionsType>
	} as unknown as Highcharts.Options;

	// Set up the map when the component has rendered, and whenever it rerenders
	useEffect(() => {
		// Don't do anything if there is no element to render the chart into
		if (chartElemRef.current === null) {
			return;
		}
		// Render the chart into the given DOM element
		const chart = Highcharts.chart(chartElemRef.current, chartOptions, undefined);

		return () => {
			// Clean up the chart
			chart.destroy();
		};
	});

	return (
		<div className="chart-container">
			<div className="chart-width" ref={chartElemRef}></div>
		</div>
	);
};

export default Chart;
export { Chart };
