import { useState, useEffect } from "react";
import Layers from "../map/layers/Layers";
import OSMLayer from "../map/layers/OSMLayer";
import Map from "../map/Map";
import MapHack from "../map/MapHack";
import * as backend from "../../apis/backend";
import WMSLayer from "../map/layers/WMSLayer";
import styles from "./FrontPageMap.module.scss";
import { CENTER_OF_NORWAY } from "../map/defaults";
import { generatePath, Link } from "react-router-dom";

const FrontPageMap = (): JSX.Element => {
	const [activeLayer, setActiveLayer] = useState<string | undefined>();
	const [wmsLayerUrl, setWmsLayerUrl] = useState<string | undefined>();

	useEffect(() => {
		// Set default layer
		const runAsync = async () => {
			const { layerNames: availableLayers, layerUrl } = await backend.wmsList();
			setActiveLayer(availableLayers[0]);
			setWmsLayerUrl(layerUrl);
		};
		void runAsync();
	}, []);

	return (
		<div className={`${styles["map-container"]}`}>
			<Link
				to={generatePath("/bade/:lat/:lng/:zoom", {
					lat: String(CENTER_OF_NORWAY.position.lat),
					lng: String(CENTER_OF_NORWAY.position.lng),
					zoom: String(CENTER_OF_NORWAY.zoom)
				})}
			>
				<Map center={CENTER_OF_NORWAY.position} zoom={3}>
					<MapHack />
					<div className={`${styles["explore-map"]}`}>
						<button className={`btn ${styles["explore-map-button"]}`}>Utforsk Kartet</button>
					</div>
					<Layers>
						<OSMLayer zIndex={0} />
						{wmsLayerUrl !== undefined && activeLayer !== undefined ? (
							<WMSLayer zIndex={1} url={wmsLayerUrl} layerName={activeLayer} />
						) : null}
					</Layers>
				</Map>
			</Link>
		</div>
	);
};

export default FrontPageMap;
export { FrontPageMap };
