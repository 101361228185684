import { Link } from "react-router-dom";
import settings from "../../settings";

interface NewsArticlePreviewProps {
	news: NewsInterface;
	orderLeft: boolean;
}

interface NewsInterface {
	title: string;
	text: string;
	link: string;
	image: string;
}

const NewsArticlePreview = ({ news, orderLeft }: NewsArticlePreviewProps): JSX.Element => {
	const orderText = orderLeft ? "order-1 order-md-1" : "order-1 order-md-0";
	const orderImage = orderLeft ? "order-0 order-md-0" : "order-0 order-md-1";
	return (
		<div className="row text-md-start text-center mt-5 mb-5">
			<div className="offset-md-3 col-md-7 col-12">
				<div className={`row align-items-center`}>
					<div className={"col-12 col-md-5 " + orderImage}>
						<img
							src={settings.PUBLIC_URL + news.image}
							alt=""
							className="full-rounded frontpage-image-size"
						/>
					</div>
					<div className={"col-12 col-md-5 " + orderText}>
						<div>
							<div>Havprat</div>
							<img src={settings.PUBLIC_URL + "/wave_short.svg"} />
						</div>
						<h2>{news.title}</h2>
						<p>{news.text}</p>
						<Link to={news.link}>
							<button type="button" className="frontpage-explain-more-button">
								Fortell mer
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsArticlePreview;
export { NewsArticlePreview };
