"use strict";

import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import settings from "../../settings";

const NavBar = (): JSX.Element => {
	// Handle dropdown state
	const [showDropdown, setShowDropdown] = useState(false);
	const [dropdownTopOffset, setDropdownTopOffset] = useState("0");
	const navbar = useRef<HTMLElement>(null);
	const dropdown = useRef(null);

	useEffect(() => {
		if (navbar.current !== null) {
			setDropdownTopOffset(navbar.current.clientHeight.toString() + "px");
		}
	}, [navbar]);

	// Close the dropdown if a click is registered outside it
	useEffect(() => {
		// Don't do anything if the dropdown is not open
		if (!showDropdown) {
			return;
		}

		const handler = (e: Event) => {
			// Ignore clicks on the dropdown itself
			if (e.target !== dropdown.current) {
				setShowDropdown(false);
			}
		};

		document.addEventListener("click", handler);

		return () => {
			document.removeEventListener("click", handler);
		};
	}, [showDropdown]);

	const handleDropdownButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		setShowDropdown(s => !s);
	};
	return (
		<nav ref={navbar} className="navbar navbar-expand background-primary-one">
			<Link to="/" className="navbar-brand">
				<img src={settings.PUBLIC_URL + "/logos/havvarsel.svg"} className="main-logo-size havvarsel-logo" />
			</Link>
			<button
				className="navbar-toggler navbar-light"
				type="button"
				data-toggle="collapse"
				data-target="#navbarToggle"
				aria-controls="navbarToggle"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse" id="navbarToggle">
				<ul className="navbar-nav margin-left-auto">
					<li role="separator" className="divider"></li>
				</ul>
			</div>
			<div id="colNav" className="collapse navbar-collapse">
				<ul className="nav navbar-nav margin-left-auto">
					<li className="nav-item">
						<button className="btn" type="button" onClick={handleDropdownButtonClick}>
							<FontAwesomeIcon className="font-color-primary-three" icon={faBars} />
						</button>
					</li>
				</ul>
			</div>
			{showDropdown ? (
				<div ref={dropdown} className="dropdown-hack" style={{ top: dropdownTopOffset }}>
					<ul className="list-unstyled">
						<li className="nav-item">
							<Link to="/om/havvarsel">Om Havvarsel</Link>
						</li>
						<li>
							<Link to="/om/slikbrukeshavvarsel">Slik bruker du havvarsel.no</Link>
						</li>
					</ul>
					<ul className="list-unstyled">
						<li>
							<Link to="/om/sjoetemperatur">Lær om sjøtemperatur</Link>
						</li>
						<li>
							<Link to="/om/saltholdighet">Lær om saltholdighet</Link>
						</li>
						<li>
							<Link to="/om/vind">Lær om vind</Link>
						</li>
						<li>
							<Link to="/om/strom">Lær om strøm</Link>
						</li>
						<li>
							<Link to="/om/soettogsalt">Smeltevann og sjøtemperatur</Link>
						</li>
						<li>
							<Link to="/om/havvarselforskjell">Er tallene nøyaktige?</Link>
						</li>
						<li>
							<Link to="/om/havet">Lær mer om havet</Link>
						</li>
					</ul>
					<ul className="list-unstyled">
						<li>
							<Link to="/om/apiet">Havvarsel sitt API</Link>
						</li>
						<li>
							<Link to="/om/prosjektet">Om prosjektet Havvarsel</Link>
						</li>
						<li>
							<Link to="/om/kommerpaahavvarsel">Kommer på havvarsel.no</Link>
						</li>
					</ul>
				</div>
			) : null}
		</nav>
	);
};

export default NavBar;
export { NavBar };
