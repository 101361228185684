import React from "react";
import { getLayerForVariable } from "../../../apis/backend/variableMaps";
import styles from "./LayerSelectorControlContainer.module.scss";

interface LayerSelectorControlContainerProps {
	availableLayers: string[];
	setActiveLayer: (layer: string | undefined) => unknown;
	activeLayer?: string;
}

const LayerSelectorControlContainer = ({
	availableLayers,
	setActiveLayer,
	activeLayer
}: LayerSelectorControlContainerProps): JSX.Element => {
	const activeTabClass = styles["select-active-tab"];
	const inactiveTabClass = styles["select-inactive-tab"];
	const inactiveLayer = styles["select-inactive-layer"];

	const temperatureLayer = getLayerForVariable("temperature", availableLayers);
	const salinityLayer = getLayerForVariable("salinity", availableLayers);
	const windLayer = getLayerForVariable("wind", availableLayers);
	const currentLayer = getLayerForVariable("current", availableLayers);

	return (
		<React.Fragment>
			<div
				className={`layer-selector-text-position ${styles["layer-selection-cell"]} ${
					activeLayer === undefined ? activeTabClass : inactiveTabClass
				}`}
				onClick={() => setActiveLayer(undefined)}
			>
				Kart
			</div>
			<div
				className={`layer-selector-text-position ${temperatureLayer === null ? inactiveLayer : ""} ${
					styles["layer-selection-cell"]
				} ${activeLayer === temperatureLayer ? activeTabClass : inactiveTabClass}`}
				onClick={() => (temperatureLayer !== null ? setActiveLayer("havvarsel_temperature") : undefined)}
			>
				Sjøtemperatur
			</div>
			<div
				className={`layer-selector-text-position ${salinityLayer === null ? inactiveLayer : ""} ${
					styles["layer-selection-cell"]
				} ${activeLayer === salinityLayer ? activeTabClass : inactiveTabClass}`}
				onClick={() => (salinityLayer !== null ? setActiveLayer("havvarsel_salinity") : undefined)}
			>
				Saltholdighet
			</div>
			<div
				className={`layer-selector-text-position ${windLayer === null ? inactiveLayer : ""} ${
					styles["layer-selection-cell"]
				} ${activeLayer === windLayer ? activeTabClass : inactiveTabClass}`}
				onClick={() => (windLayer !== null ? setActiveLayer("havvarsel_wind") : undefined)}
			>
				Vind
			</div>
			<div
				className={`layer-selector-text-position ${currentLayer === null ? inactiveLayer : ""} ${
					styles["layer-selection-cell"]
				} ${activeLayer === currentLayer ? activeTabClass : inactiveTabClass}`}
				onClick={() => (currentLayer !== null ? setActiveLayer("havvarsel_current") : undefined)}
			>
				Strøm
			</div>
		</React.Fragment>
	);
};

export default LayerSelectorControlContainer;
export { LayerSelectorControlContainer };
