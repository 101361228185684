"use strict";
import React, { useEffect } from "react";
import NavBar from "../navbar/Navbar";
import { Footer } from "../common/Footer";
import GoogleAnalytics from "../application/GoogleAnalytics";
import { useLocation } from "react-router-dom";

interface LayoutProps {
	children?: React.ReactNode;
}
const ScrollToTopOnLocationChange = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);
	return null;
};

const Layout = ({ children }: LayoutProps): JSX.Element => {
	return (
		<React.Fragment>
			<NavBar />
			<GoogleAnalytics />
			<ScrollToTopOnLocationChange />
			<main>{children}</main>
			<Footer />
		</React.Fragment>
	);
};

export default Layout;
export { Layout };
