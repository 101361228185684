import * as yup from "yup";

// TODO: Fix this yup, add more details
export const WindAndCurrentSchema = yup.object({
	metadata: yup.array(yup.object().required()),
	data: yup
		.array(
			yup
				.object({
					coords: yup.object({ lon: yup.number().required(), lat: yup.number().required() }).required(),
					wind: yup
						.object({ direction: yup.number().required(), strength: yup.number().required() })
						.required(),
					current: yup
						.object({ direction: yup.number().required(), strength: yup.number().required() })
						.required()
				})
				.required()
		)
		.min(1)
		.required()
});

export type WindAndCurrent = yup.Asserts<typeof WindAndCurrentSchema>;
