import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const LOCALSTORAGE_KEY = "ToS-accepted-date";

function TosModal() {
	const acceptedDateStr = localStorage.getItem(LOCALSTORAGE_KEY);
	const acceptedDate = acceptedDateStr ? new Date(acceptedDateStr) : null;
	const [accepted, setAccepted] = useState(acceptedDate !== null);

	const handleAccept = () => {
		// Store the timestamp when it was accepted in localstorage
		localStorage.setItem(LOCALSTORAGE_KEY, new Date().toISOString());
		setAccepted(true);
	};

	return (
		<>
			<Modal show={!accepted}>
				<Modal.Header>
					<Modal.Title>Datainnsamling</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Bruk av tjenesten krever at Havforskningsinstituttet kan samle inn data om dine trykk i kartet,
						søk, og geolokasjon.
					</p>
					<p>
						Dette er nødvendig for å kunne vise deg data for punktet du ønsker. Søketjenesten leveres av
						Kartverket gjennom geonorge.no. Søkedata deles derfor med Kartverket. I tillegg vil
						søkeresultatet lagres i nettleseren, slik at du raskt får tilgang til de siste stedene du søkte
						på.
					</p>
					<p>
						Havforskningsinstituttet vil, utover å tilby data for punktet du ønsker, bruke posisjonsdataene
						til å kartlegge hvor tjenesten brukes, slik at den over tid kan gjøres bedre.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleAccept}>Godta</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default TosModal;
