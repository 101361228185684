import * as yup from "yup";

export const getProjectionSchema = yup.object({
	depthInMeters: yup.number().required(),
	depthIndex: yup.number().required(),
	closestGridPointWithData: yup.object({
		lat: yup.number().required(),
		lon: yup.number().required()
	}),
	variables: yup
		.array(
			yup
				.object({
					data: yup
						.array(
							yup
								.object({
									value: yup.number().required(),
									rawTime: yup.number().required()
								})
								.required()
						)
						.required(),
					variableName: yup.string().required()
				})
				.required()
		)
		.required()
});

export type GetProjection = yup.Asserts<typeof getProjectionSchema>;
