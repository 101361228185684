import { Fill, Stroke, Style, Text } from "ol/style";
import { Current, Wind } from "../../../apis/backend/types/Wind";

const getArrowStyle = (data: Wind | Current, colorScale: Map<number, string>): Style[] => {
	const strength = data.strength;
	const direction = data.direction;

	const arrowColor = (arrowStrength: number, colorScale: Map<number, string>): string => {
		const scale = [...colorScale.keys()];
		const output = scale.reduce(
			(prev, curr) => (Math.abs(curr - arrowStrength) < Math.abs(prev - arrowStrength) ? curr : prev),
			0
		);
		return colorScale.get(output) ?? "red";
	};

	const color = arrowColor(strength, colorScale);

	// Openlayers's rotation is "positive clockwise", unlike normal math where it is counter clockwise, make it absolute minus value
	// Use character '→' so that openlayers does not have to draw arrows, takes a lot of time to render
	//XXX: Current has 1 decimal, wind has 0. Wind need to be rounded before color is decided, so the colors does not get misleadning on the page.
	// For example two arrows having 2 m/s have different color.
	const styles = [
		new Style({
			text: new Text({
				text: `➞`,
				rotation: -Math.abs(direction),
				scale: 2,
				stroke: new Stroke({ color: "grey", width: 1 }),
				fill: new Fill({ color: color })
			})
		})
	];
	return styles;
};

export default getArrowStyle;
export { getArrowStyle };
