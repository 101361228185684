// These needs to be same as gradient in ColorBar.module.scss or else arrow-color will not match the gradient.
const currentColorScale = new Map([
	[0, "#f2e660"],
	[0.05, "#f9c62c"],
	[0.1, "#fca50a"],
	[0.15, "#f9850e"],
	[0.2, "#ed6925"],
	[0.25, "#c73f4d"],
	[0.3, "#ae2f5c"],
	[0.35, "#932667"],
	[0.4, "#771c6d"],
	[0.45, "#5c146e"],
	[0.5, "#000003"]
]);

// These needs to be same as gradient in ColorBar.module.scss or else arrow-color will not match the gradient.
const windColorScale = new Map([
	[0, "#fde725"],
	[1, "#dce319"],
	[2, "#b8de29"],
	[3, "#94d941"],
	[4, "#74d055"],
	[5, "#56c667"],
	[6, "#3cbc75"],
	[7, "#28af7f"],
	[8, "#20a386"],
	[9, "#1f978b"],
	[10, "#238a8d"],
	[11, "#277d8e"],
	[12, "#2d708d"],
	[13, "#32638e"],
	[14, "#39558c"],
	[15, "#3f4788"],
	[16, "#453782"],
	[17, "#482678"],
	[18, "#481568"],
	[19, "#2b0d40"],
	[20, "#000003"]
]);

export { currentColorScale, windColorScale };
