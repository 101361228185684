import * as yup from "yup";
import { punktSchema } from "./Punkt";
import { metadataSchema } from "./Metadata";

export const returPunktSchema = yup.object({
	metadata: metadataSchema.required(),
	navn: yup.array(punktSchema.required()).required()
});

export type ReturPunkt = yup.Asserts<typeof returPunktSchema>;
