import queryString from "query-string";
import jsonFetcher from "../../../fetchers/jsonFetcher";
import { PunktParams } from "./types/PunktParams";
import { ReturPunkt, returPunktSchema } from "./types/ReturPunkt";
import { NavnParams } from "./types/NavnParams";
import { ReturSkrivemate, returSkrivemateSchema } from "./types/ReturSkrivemate";
import { Schema } from "yup";
import { Skrivemate } from "./types/Skrivemate";
import { Place } from "../../../types/Place";

const BASE_URL = "https://ws.geonorge.no/stedsnavn/v1/";

/** Fetches locations of place names in Norway which match the search */
export const navn = (params: NavnParams): Promise<ReturSkrivemate> =>
	// XXX SchemaOf is a typehack to compensate for what I believe is a bug in yup. See bug report HAV-63
	jsonFetcher<ReturSkrivemate>(returSkrivemateSchema as Schema<ReturSkrivemate>)(
		`${BASE_URL}/navn?${queryString.stringify(params)}`
	);

/** Fetches list of place names in Norway close to a given geographical point */
export const punkt = (params: PunktParams): Promise<ReturPunkt> =>
	jsonFetcher<ReturPunkt>(returPunktSchema)(`${BASE_URL}/punkt?${queryString.stringify(params)}`);

/** Converts a Skrivemåte object to a Place object */
export const skrivemåteToPlace = (skrivemåte: Skrivemate): Place => ({
	name: skrivemåte.skrivemåte,
	placeType: skrivemåte.navneobjekttype,
	kommune: skrivemåte.kommuner?.[0].kommunenavn ?? null,
	position: {
		lat: skrivemåte.representasjonspunkt.nord,
		lng: skrivemåte.representasjonspunkt.øst
	}
});
