/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useTranslation } from "react-i18next";
import { LatLng } from "../../types/LatLng";
import styles from "./DisplayData.module.scss";

interface DetailsProps {
	position: LatLng;
}

const Details = ({ position }: DetailsProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div>
			<div className={`font-color-primary-one ${styles["display-data-title-font"]}`}>Detaljer</div>
			<div className="font-color-primary-one">
				{t("geo:latitude")}: {position.lat.toFixed(3)}
			</div>
			<div className="font-color-primary-one">
				{t("geo:longitude")}: {position.lng.toFixed(3)}
			</div>
		</div>
	);
};

export default Details;
export { Details };
