import settings from "../../settings";

const Footer = (): JSX.Element => {
	return (
		<footer className="background-primary-three font-color-primary-three">
			<div className="footer-wave" />
			<div className="background-primary-two">
				<div className="container">
					<div className="row mb-5">
						<div className="col-12 col-md-4 text-center text-md-start mt-4">
							<p>
								Havvarsel er en tjeneste levert av Havforskningsinstituttet og Meteorologisk institutt,
								med utviklingsstøtte fra SINTEF.
							</p>
						</div>
						<div className="col-12 col-md-4 text-center text-md-start mt-4 mb-4">
							<p className="bold-text">Har du spørsmål eller tilbakemeldinger?</p>
							<p>
								Se{" "}
								<a href="https://metno.github.io/havvarsel-website/faq/" target="_blank">
									<span className="white-color url-underline">ofte stilte spørsmål</span>
								</a>
							</p>
							<p>
								Send oss en mail på{" "}
								<a href="mailto:havvarsel@hi.no?subject=Havvarsel%20kontakt">
									<span className="white-color url-underline">havvarsel@hi.no</span>
								</a>
							</p>
						</div>
						<div className="col-12 col-md-4 text-center text-md-start mt-4">
							<div className="white-color bold-text">Vil du vite mer om oss?</div>
							<div className="mt-3">
								<a href="https://www.hi.no" target="_blank">
									<span className="white-color url-underline">Havforskningsinstituttet</span>
								</a>
							</div>
							<div className="mt-3">
								<a href="https://www.met.no" target="_blank">
									<span className="white-color url-underline">Meteorologisk institutt</span>
								</a>
							</div>
							<div className="mt-3">
								<a href="https://www.sintef.no" target="_blank">
									<span className="white-color url-underline center-block">SINTEF</span>
								</a>
							</div>
						</div>
					</div>
					<div className="row mt-5 align-items-center">
						<div className="col-12 offset-md-1 col-md-3 mt-3 mb-3 text-center">
							<a href="https://www.hi.no" target="_blank">
								<img src={settings.PUBLIC_URL + "/logos/hi_logo.svg"} className="logo-hi" />
							</a>
						</div>
						<div className="col-12 col-md-3 mt-3 mb-3 text-center">
							<a href="https://www.met.no" target="_blank">
								<img src={settings.PUBLIC_URL + "/logos/met_logo.svg"} className="logo-met" />
							</a>
						</div>
						<div className="col-12 col-md-3 mt-3 mb-3 text-center">
							<a href="https://www.sintef.no" target="_blank">
								<img src={settings.PUBLIC_URL + "/logos/sintef_logo.svg"} className="logo-sintef" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
export { Footer };
