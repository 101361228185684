import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as geonorgeStedsnavn from "../../apis/geonorge/stedsnavn";
import { ReturSkrivemate } from "../../apis/geonorge/stedsnavn/types/ReturSkrivemate";
import { Skrivemate } from "../../apis/geonorge/stedsnavn/types/Skrivemate";
import styles from "./GeoNorgeNavnSearch.module.scss";

/** How long to delay the search after last keypress, in ms */
const DEBOUNCE_TIME = 500;

/** Desired name object type order */
const NAME_OBJECT_TYPE_ORDER = ["By", "Kommune"];

/** Prepares the raw data from GeoNorge's API for use */
const prepareResults = (data: ReturSkrivemate): Skrivemate[] => {
	// XXX Clone the data. `Array.prototype.sort` is one of the functions from the ancient JS days which mutates
	// the array
	return [...data.navn].sort((a, b) => {
		// Sort by name object type
		let ia = NAME_OBJECT_TYPE_ORDER.indexOf(a.navneobjekttype);
		let ib = NAME_OBJECT_TYPE_ORDER.indexOf(b.navneobjekttype);

		// If the type is not in the defined order, it is last in it
		if (ia < 0) {
			ia = Infinity;
		}
		if (ib < 0) {
			ib = Infinity;
		}

		return ia - ib;
	});
};

interface GeoNorgeNavnSearchProps {
	onResult: (data: Skrivemate) => void;
	defaultValue?: string;
}

const GeoNorgeNavnSearch = ({ onResult, defaultValue }: GeoNorgeNavnSearchProps): JSX.Element => {
	// Get hold of the translation object
	const [t, ready] = useTranslation();

	// Keep track of the debouncer timeout
	const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);

	// Need to keep a search ID around to make sure only the newest search shows its results
	const searchIdRef = useRef<symbol | null>(null);

	// Keep track of the current search string
	const inputRef = useRef<HTMLInputElement>(null);
	const getSearchStr = () => inputRef.current?.value ?? "";

	// Store the results
	const [showResults, setShowResults] = useState<"hide" | "loading" | "show">("hide"); // TODO "loading" is currently set correctly, but functions just like "hide"
	const [results, setResults] = useState<Skrivemate[] | null>(null);

	/** Handle changes to the search input */
	const handleChange = () => {
		// Get the search string from the form
		const searchStr = getSearchStr().replace(/[^ÆØÅæøåA-Z0-9]/gi, "_");

		// Clear the running timeout, if there is one
		if (searchTimeout !== null) {
			clearTimeout(searchTimeout);
		}

		// Do not search for the empty string
		if (!searchStr) {
			// Hide the results
			setShowResults("hide");

			return;
		}

		// Tell the user the results are loading
		setShowResults("loading");

		// Start the new timeout
		const timeout = setTimeout(() => {
			// Make a search ID for this search. Symbols will never collide
			const id = Symbol();
			searchIdRef.current = id;

			void (async () => {
				// Fetch the data
				const data = await geonorgeStedsnavn.navn({
					fuzzy: true,
					treffPerSide: 50,
					sok: searchStr
				});

				// Check that this is indeed the freshest request. While waiting for the response, another request
				// could have been sent, and even finished. This prevents stale data
				if (id !== searchIdRef.current) {
					return;
				}

				// Reset the search ID
				searchIdRef.current = null;

				// Update the results
				setResults(prepareResults(data));
				setShowResults("show");
			})();
		}, DEBOUNCE_TIME);
		setSearchTimeout(timeout);
	};

	const handleResult = useCallback(
		(result: Skrivemate) => {
			// Hide the results
			setShowResults("hide");

			// Pass the result on
			onResult(result);
		},
		[onResult, setShowResults]
	);

	// Update the value displayed in the input if there is none, and a default value is provided
	useEffect(() => {
		if (defaultValue === undefined || inputRef.current === null || inputRef.current.value !== "") {
			return;
		}

		inputRef.current.value = defaultValue;
	}, [defaultValue]);

	// Make enter select the first result
	useEffect(() => {
		const input = inputRef.current;

		const handleKeypress = (e: KeyboardEvent) => {
			if (results === null || results.length === 0) {
				return;
			}

			if (e.key !== "Enter") {
				return;
			}

			handleResult(results?.[0]);
		};

		input?.addEventListener("keypress", handleKeypress);

		return () => {
			input?.removeEventListener("keypress", handleKeypress);
		};
	}, [handleResult, results]);

	return (
		<div className="form-control">
			{/* XXX If onBlur doesn't have a delay, it triggers immediately when clicking a result, causing
			the result to hide before its click event can fire */}
			<input
				className={`form-control ${styles["search-field"]}`}
				ref={inputRef}
				type="text"
				onChange={handleChange}
				onFocus={() => setShowResults("show")}
				onBlur={() => setTimeout(() => setShowResults("hide"), 100)}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
				placeholder={ready ? t("geonorge:stedsnavn:searchForPlace") : "Loading..."}
				defaultValue={defaultValue ?? ""}
			/>
			{showResults === "show" ? <SearchResults results={results ?? []} onResult={handleResult} /> : null}
			{showResults === "loading" ? <Loading /> : null}
		</div>
	);
};

interface SearchResultsProps {
	results: Skrivemate[];
	onResult: (data: Skrivemate) => void;
}

const SearchResults = ({ results, onResult }: SearchResultsProps): JSX.Element => {
	const handleSelect = (result: Skrivemate) => () => onResult(result);

	return (
		<ul className={`list-group ${styles["search-results"]}`}>
			{results.map((result, i) => (
				<li key={i} className="list-group-item list-group-item-action">
					<a onMouseDown={handleSelect(result)} onTouchStart={handleSelect(result)}>
						<p>{result.skrivemåte}</p>
						<p>
							{result.navneobjekttype}, {result.kommuner?.[0].kommunenavn}
						</p>
					</a>
				</li>
			))}
		</ul>
	);
};

const Loading = (): JSX.Element => (
	<ul className={`list-group ${styles["search-results"]}`}>
		<li className="list-group-item">Søker...</li>
	</ul>
);

export default GeoNorgeNavnSearch;
export { GeoNorgeNavnSearch };
