import React from "react";
import { getPlaces, Place } from "../../types/Place";
import SuggestedPlace from "../tables/SuggestedPlace";

export const DEFAULT_PLACES = [
	{
		name: "Bergen",
		placeType: "By",
		kommune: "Bergen",
		position: {
			lat: 60.39323,
			lng: 5.3245
		}
	},
	{
		name: "Oslo",
		placeType: "By",
		kommune: "Oslo",
		position: {
			lat: 59.91273,
			lng: 10.74609
		}
	},
	{
		name: "Tromsø",
		placeType: "By",
		kommune: "Tromsø",
		position: {
			lat: 69.65455,
			lng: 18.96366
		}
	}
] as Place[];

const FrontPageSuggestedPlace = (): JSX.Element => {
	// Get the saved places and concatenate the default ones
	const places = [...getPlaces(), ...DEFAULT_PLACES].slice(0, 3);

	return (
		<React.Fragment>
			{places.map((place, i) => (
				<div className="row" key={i}>
					<div className={`col-12 mb-2 ${i === 0 ? "mt-2" : ""}`}>
						<SuggestedPlace
							name={place.name}
							placeType={place.placeType}
							kommune={place.kommune}
							position={place.position}
						/>
					</div>
				</div>
			))}
		</React.Fragment>
	);
};

export default FrontPageSuggestedPlace;
export { FrontPageSuggestedPlace };
