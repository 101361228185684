import mapLayers from "./maplayers";
import frontpage from "./frontpage";
import application from "./application";
import geonorge from "./geonorge";
import geo from "./geo";

const nb = {
	frontpage,
	mapLayers,
	application,
	geonorge,
	geo
};

export default nb;
export { nb };
