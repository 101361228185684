/** VariableOptions for Chart.tsx */
const variableOptions = {
	temperature: {
		title: "Temperatur",
		tooltipPointFormat: "{series.name}: <b>{point.y} °C</b><br/>",
		lineColor: "#FF3333",
		YLabelText: "Sjøtemp.",
		YAxisLabel: "{value}°"
	},
	salinity: {
		title: "Salt",
		tooltipPointFormat: "{series.name}: <b>{point.y} </b><br/>",
		lineColor: "#1422E0",
		YLabelText: "Salthold.",
		YAxisLabel: "{value}"
	},
	wind: {
		title: "Wind",
		tooltipPointFormat: "{series.name}: <b>{point.y}</b><br/>",
		lineColor: "#1422E0",
		YLabelText: "Vind",
		YAxisLabel: "{value}"
	},
	current: {
		title: "Current",
		tooltipPointFormat: "{series.name}: <b>{point.y}</b><br/>",
		lineColor: "#1422E0",
		YLabelText: "Strøm",
		YAxisLabel: "{value}"
	}
} as const;

export { variableOptions };

/** VariableOptions for HeatMap.tsx */
const variableOptionsHeatMap = {
	temperature: {
		title: "Temperatur",
		tooltipPointFormat: "<b>{point.value} °C</b><br/>",
		YLabelText: "Dybde",
		YAxisLabel: "{value}°"
	},
	salinity: {
		title: "Salt",
		tooltipPointFormat: "<b>{point.value} </b><br/>", // Add "‰" here if you want it shown in the heatmap
		YLabelText: "Dybde",
		YAxisLabel: "{value}"
	},
	wind: {
		title: "Wind",
		tooltipPointFormat: "{series.name}: <b>{point.y}</b><br/>",
		lineColor: "#1422E0",
		YLabelText: "Vind",
		YAxisLabel: "{value}"
	},
	current: {
		title: "Current",
		tooltipPointFormat: "{series.name}: <b>{point.y}</b><br/>",
		lineColor: "#1422E0",
		YLabelText: "Strøm",
		YAxisLabel: "{value}"
	}
} as const;

export { variableOptionsHeatMap };
