import * as yup from "yup";

/**
 * Creates a fetcher which fetches and validates JSON from an URL
 *
 * @param schema	Schema used to validate the fetched data
 *
 * @returns		Function for fetching data
 */
const jsonFetcher =
	<Data>(schema: yup.Schema<Data>) =>
	(url: string): Promise<Data> =>
		fetch(url)
			.then(res => res.json())
			.then(data => schema.validate(data));

export default jsonFetcher;
export { jsonFetcher };
