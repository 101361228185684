import React from "react";
import { useParams } from "react-router-dom";
import Overview from "../pages/Overview";
import useMapNavigation from "./useMapNavigation";
import { posParser } from "./utils";

const OverviewRoute = () => {
	const { lat, lng } = useParams<{ lat?: string; lng?: string }>();

	const { setPosZoom } = useMapNavigation({
		pathTemplate: "/oversikt/:lat/:lng"
	});

	if (lat === undefined || lng === undefined) {
		// Handle the case where lat or lng is undefined, e.g., redirect or show an error message
		return <p>Error: Latitude or Longitude is missing.</p>;
	}

	const params = posParser({ lat, lng });

	return <Overview position={posParser({ lat, lng })} setPosition={() => setPosZoom(params)} />;
};

export default OverviewRoute;
